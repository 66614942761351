import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import { Link } from "@reach/router";
import Style from "./consultancy.style";
import { useLocation } from "@reach/router";

export default function Consultancy() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <header>
        <Navbar />
      </header>
      <Style.DevelopWrapper>
        <section className="header-wrapper">
          <div className="inner-padding-content">
            <Style.DevelopHeader>
              <div className="hero-item">
                <h3>Consultancy</h3>
                <p>
                  Consultancy is more than just advising. It is strategic.
                </p>
                <a href="#down" className="main-btn">
                  Explore
                </a>
              </div>
            </Style.DevelopHeader>
          </div>
        </section>
      </Style.DevelopWrapper>
      <section className="inner-padding-content" id="down">
        <Style.DevelopMain>
          <div className="main-wrapper">
            <div>
              <h2>
                <span>
                  Let’s walk.
                </span>

              </h2>
              <p>
                We create a pathway for businesses with a hunger to become something or companies in need of change, achievement, and attainment in branding and software implementation decisions.
              </p>
            </div>
            {/* <div className="main-left">
              <span>
                <Link to="/Iot">IOT & Embedded Systems</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Digital Marketing</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Consultancy</Link>
              </span>
            </div>
           */}
          </div>
        </Style.DevelopMain>
      </section>
      <Style.ContentWrapper>
        <main>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content title-content">
                <div className="content-left tile-container__item ">
                  <img
                    className="tile-container__item"
                    src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  ></img>
                </div>
                <div className="content-right tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>Live the dream.</h2>
                    <p>
                      At Raaxo Synergy, we help businesses grow and move from their current state to their desired state in the market. Our team of professionals supports you in solving your problems to achieve your preferred results.
                    </p>
                    <Link to="/develop" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content">
                <div className="content-left tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>The Blueprint.</h2>
                    <p>
                      Our expertise and deep technological background creates a comprehensive strategy for digital and technological transformation.
                    </p>
                    <Link to="/iot" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
                <div className="content-right tile-container__item">
                  <img
                    className="tile-container__item"
                    src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  ></img>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
        </main>
      </Style.ContentWrapper>
      <Style.Footer>
        <div className="footer">
          <Footer />
        </div>
      </Style.Footer>
    </>
  );
}
