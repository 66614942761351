import React,{useState} from "react";
import axios from "axios"
import Style from "./newsletter.style";

export default function Newsletter(props) {
const [email, setemail] = useState("");
// const [loading, setLoading] = useState(false)

const handleChange =  (e) => {
  setemail(e.target.value);
}; 
 const  handleSubmit = (e) => {
    // setLoading(true)
    e.preventDefault();

    const user = {
      email: email
    };

    axios.post(`https://jsonplaceholder.typicode.com/users`, user )

      .then((res) => {
        console.log(res);
        console.log(res.data);
      }).catch( (error) => {

            // setLoading(false)
            console.log(error)
            
      })

 
    }

  return (
    <div>
      <Style.NewsLetter>
        <div className="newsletter-form inner-padding-content">
          <div className="newsletter-left">
            <div>
              <h4>Newsletter</h4>
              <h2>What's happening? Be the first to know.</h2>
              <p>
                Subscribe for insider info about out latest news, products,
                promotions and events.
              </p>
            </div>
            <div>
              <h1 className="outline"> N</h1>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="inner-padding-content form-field">
          <div className="email-input">
            <input type="email" placeholder="Email*" onChange={handleChange} />
          </div>
          <div className="check-box">
            <input type="checkbox" />
            <p>
              By submitting your data and signing up to receive our news
              updates, you authorise Critical Software to process it as
              described in the Terms and Privacy Policy.*
            </p>
          </div>
          <button className="main-btn button" type="submit">
            Subscribe
          </button>
        </form>
      </Style.NewsLetter>
    </div>
  );
}
