import Styled from "styled-components";
import Headerbg from "../../assets/img/colored_computer.png";
const Style = {

  RaaxoBrand: Styled.div`
   display:flex;
   background:
   linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /25%)),url(${Headerbg});
   background-size: cover;
   background-position: 50% 20%;
   width:100%;
   height:100%;
   
  .header-wrapper{
      height:100vh;
      display:flex;
      align-items:center;
      width:50vw;
      @media screen and (max-width: 1000px) {
        
           width:100vw;
        
      }
      h1{
          font: normal normal bold 48px/50px Century Gothic;
          color: ${({ theme }) => theme.yellow};
      }
      p{
          font: normal normal normal 20px/26px Century Gothic;
          line-height:1.5em;
          color: ${({ theme }) => theme.lightBlue};
           @media screen and (max-width: 600px) {
                font: normal normal normal 16px/24px Century Gothic;
           }
      }
  }
`,
BrandSectiontwo: Styled.section `
margin-bottom:10em;
 .branding-contents{
    display:flex;
    align-items:center;
    justify-content:space-between;

     flex-wrap:wrap;
 .brand-text {
  h1{
      font: normal normal bold 50px/70px Century Gothic;
      color: ${({ theme }) => theme.lightBlue};
      width:45vw;
      span {
          color: ${({ theme }) => theme.yellow};
      }
      
  }
  p{
      font: normal normal normal 20px/26px Century Gothic;
      color: ${({ theme }) => theme.lightBlue};
      width:50vw;
     
      
  }
  @media screen and (max-width: 1200px) {
     
      h1{
          font: normal normal bold 24px/40px Century Gothic;
        width:100vw;
      }
        p{
            font: normal normal normal 16px/24px Century Gothic;
            width:100%;
        }
      }
 }
 .links{
     margin-top:20em;
      @media screen and (max-width: 1200px) {

          margin-top:0;
      }
     span{
         display:flex;
         flex-direction:column;
         
         padding-top:1em;
         font: normal normal 300 15px/25px Raleway;
          color: ${({ theme }) => theme.lightBlue};
        
     }
 }
 }
 .brand-content{
     display:flex;
     align-items:center;
     /* justify-content:space-between; */
    
     
       .tile-container__item{
          width: 100%;
          object-fit: cover;
        }
          
    @media screen and (max-width: 1200px) {
        display:block;
        margin:2em 0;
         .tile-container__item > img {
             display:none;
         }
         
    }
        h2{
            font: normal normal bold 28px/40px Century Gothic;
            color: ${({ theme }) => theme.yellow};
        }
        p{
            font: normal normal normal 20px/26px Century Gothic;
            color: ${({ theme }) => theme.lightBlue};
        }
    @media screen and (max-width: 600px) {
         h2{
             font: normal normal bold 38px/40px Century Gothic;
         }
         p{
             font: normal normal normal 16px/24px Century Gothic;
         }
    }
 }


`,


};
export default Style;