import Styled from "styled-components";

const Style = {
  ProjectHero: Styled.div `
    
    .hero-wrapper{
        height:60vh;
        display:flex;
        justify-content:center;
        align-items:center;
        h1{
            font: normal normal bold 100px/103px Century Gothic;
            color: ${({ theme }) => theme.darkBlue};
         @media screen and (max-width: 600px) {
         font: normal normal bold 28px/30px Century Gothic;
      }
        }
        @media screen and (max-width: 600px) {
         height:50vh;
      }
    }
    `,

  ProjectStyle: Styled.div `
    .project-wrapper{
        display:flex;
        flex-wrap: wrap;
        justify-content:center;
        /* align-items:center; */
        margin:0 auto;
        
    }

    .project {
      margin: 2.5em 1em;
      a {
        img {
          object-fit: cover;
          max-width: 400px;
          width: 100%;
          height: 450px;
          background: white;

          @media (max-width: 770px) {
            height: 350px;
          }

           @media (max-width: 420px) {
            height: 200px;
          }
        }        
      }


      .project-details {
        margin-top: 2em;
        max-width: 569px;

        &__header {
          display: flex;
          align-items: center;
          text-transform: capitalize;

          img {
            width: 37px;
            height:37px;
            border-radius: 50%;
            margin-right: .5em
          }

          p {
            margin: 0;
            padding: 0;
            color: ${({ theme }) => theme.lightBlue};
            font-weight: 500;
          }
        }

        &__footer {
          margin-top: 1em;
          display: flex;
          justify-content: space-between;
          text-transform: capitalize;

          p {
            margin: 0;
            padding: 0;
            
            &:first-child {
              font-size: 1.9em;
              font-weight: 400;
              max-width: 490px;
              color: ${({ theme }) => theme.yellow};
              line-height: 1.1;
            }
          }

          a {
            text-decoration: none;
            cursor: pointer;

            span {
              font-weight: 100;
              font-size: 2em;
              color: ${({ theme }) => theme.lightBlue};
              margin-right: .5em;
            }

          }

        }

        @media (max-width: 770px) {
            margin-top: .8em;

          &__header {

          img {
            max-width: 20px;
          }

          p {
          
           font-size: .8em;
          }
        }

                &__footer {
          margin-top: .5em;


          p {
            
            &:first-child {
              font-size: .95em;
            }
          }

          a {
            text-decoration: none;
            cursor: pointer;

            span {
              font-size: 1em;
              margin-right: .5em;
            }

          }

        }

          }

           @media (max-width: 420px) {
          }
      }
    }

    `,
  Filter: Styled.div `
    .filter {
        display:flex;
        /* flex-direction:column; */
        justify-content:center;
        align-items:center;
        h1{
            font: normal normal normal 65px/67px Century Gothic;
            color: ${({ theme }) => theme.lightBlue};
            @media screen and (max-width: 600px) {
            font: normal normal normal 28px/30px Century Gothic;
      }
     }
        .categories{
            display:flex;
            justify-content:space-evenly;
            align-items:center;
            width:100%;
            flex-wrap:wrap;
            
            p{
                font: normal normal normal 24px/30px Century Gothic;
                color: ${({ theme }) => theme.lightBlue};
                &:hover{
                 border-bottom:4px solid ${({ theme }) => theme.placeholder};
                 opacity:0.5;
                }
                padding:0em;
                 @media screen and (max-width: 320px) {
                 /* padding:0 1em; */
                 font: normal normal normal 16px/24px Century Gothic;
              
           
          }

        }

            .category-item{
                border-radius:50px;
                padding: 0 1em;
                cursor:pointer;
                
                 @media screen and (max-width: 320px) {
            
                margin:0 .3em;
           
                }
                
            }
            .item1{
                  /* background:${({ theme }) => theme.placeholder}; */
            }
            .item2{
                /* border: 2px solid ${({ theme }) => theme.placeholder};s */
            }
        }
    }
    `,
};
export default Style;