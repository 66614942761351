import React from "react";
import TeamModalPortal from "./TeamModalPortal";
import Styled from "./teamModal.styles";

const TeamModal = (props) => {
  return (
    <TeamModalPortal>
      <Styled>
        <div className="modal__backdrop" onClick={props.toggle}>
          <div className="modal-content">
            <div class="modal-content__item first">
              <img src={props.teamMember.cover_image} alt={props.teamMember.name} />
            </div>

            <div className="modal-content__item last">
              <div className="modal-content__item-header">
                <h4>{props.teamMember.name}</h4>
                <p>{props.teamMember.title}</p>
                <span className="close" onClick={props.toggle}>
                  &times;
                </span>
              </div>
               
              <div className="modal-content__item-footer">
                {props.teamMember.description}
              </div>
            </div>
          </div>
        </div>
      </Styled>
    </TeamModalPortal>
  );
};

export default TeamModal;
