export const darkTheme = {
    background: "#040616",
    lightBlue: "#B4B7FF",
    darkBlue: "#303247",
    yellow: "#FBE682",
    footerBackground: "#101221",
    footerDarkBlue: "#4F5487",
    placeholder:"#171A37",
    navyBlue:"#171A37",
    lightGrey:"#6D6E87",
    midBlue:"#0C0E21",
    white: "#FAFAFA",
    lightestYellow:"#E9E1B9",
    
};

export const lightTheme = {
    black: "#2C2C2C",
    yellow: "#FF9F0D",
    grey: "#ACACAC",
   
};

