import styled from "styled-components";

const Styled = styled.div`
  .modal__backdrop {
    z-index: 10000;
    background: rgba(0, 0, 0, 0.75);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1000;

    .modal-content {
      background: ${({ theme }) => theme.background};
      border-radius: 1.5em;
      width: 100%;
      margin: 50px auto;
      max-width: 950px;
      padding: 2em;
      display: flex;

      @media (max-width: 754px) {
        flex-direction: column;
      }

      .modal-content__item {
        padding: 1em;

        &.first {
          width: 40%;
          // height:40%;
          // border-radius: 50%;
          img {
            width: 16em;
            height: 16em;
            min-height: 10em;
            min-width: 10em;
            border-radius: 100%;
            object-fit: contain;
            background: #dee7c9;
          }

          @media (max-width: 754px) {
            width: 100%;
            text-align: center;

            img {
              max-width: 250px;
              min-height: 5em;
            }
          }
        }

        &.last {
          width: 60%;
          position: relative;

          .modal-content__item-header {
            h4 {
              color: ${({ theme }) => theme.yellow};
              text-transform: capitalize;
              font-size: 2.2em;
              padding: 0;
              margin: 0;
              font-weight: 700;
            }
            @media screen and (max-width: 320px) {
              h4 {
                font-size: 0.1em;
              }
            }

            p {
              color: ${({ theme }) => theme.lightBlue};
              text-transform: capitalize;
              padding: 0;
              margin: 0;
              opacity: 0.5;
            }

            .close {
              color: ${({ theme }) => theme.lightBlue};
              border: 2px solid ${({ theme }) => theme.lightBlue};
              padding: 0.79em 0.5em;
              border-radius: 50%;
              align-content: center;
              font-size: 1.9em;
              font-weight: 700;
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
              line-height: 0;
            }
          }

          .modal-content__item-footer {
            color: ${({ theme }) => theme.lightBlue};
            margin-top: 0.5em;
            line-height: 1.3;
            font-size: 1.2em;
            font-weight: 400;
          }

          @media (max-width: 754px) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`;

export default Styled;
