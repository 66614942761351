import Styled from "styled-components";
// import img from "../../assets/img/shutterstock_652948648@2x.png";

const Style = {
  Main: Styled.div `
  
    display:flex;
    width:100%;
    height:100vh;
 
  `,
  IotMain: Styled.div `
  height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
         margin-top:3em;
          }
  
  
  h2 {
    font-size: 3vw;
    font-weight: 800;
    color: ${({ theme }) => theme.yellow};
    @media screen and (max-width: 600px) {
          font-size: 8vw;
          }
    
  }
  
  .divider {
      width: 100%;
      border-top: 0.03em solid ${({ theme }) => theme.yellow};
      opacity: 0.2
    }

  p{
    
      color: ${({ theme }) => theme.lightBlue};
      margin-bottom: 2em;
      font-size: 20px;
      line-height:1.5em;
      
    }
.content-right > img {
    width: 50vw;
}
@media screen and (max-width: 600px) {
            .content-right > img {
                display:none;
        }
    }
  `,

  IotSub: Styled.div `
  /* height:100vh; */

  .iot-wrapper{
    display: flex;
    /* justify-content:center; */
    align-items:center;
    
    .outline{
    -webkit-text-stroke: .4px ${({ theme }) => theme.yellow};
    color: transparent;
    font-size: 25em;
    font-weight: 800;
    opacity:0.9;
      @media screen and (max-width: 600px) {
     font-size: 15em;
   }
    }
    h2{
      font: normal normal bold 227px/236px Century Gothic;
      color: ${({ theme }) => theme.yellow};
      margin-top:-2.9em;
      margin-left:25px;
      @media screen and (max-width: 600px) {
     font: normal normal bold 128px/106px Century Gothic;
   }
    }
    .heading{
      font: normal normal 300 66px/110px Raleway;
      color: ${({ theme }) => theme.lightBlue};
       margin-top:-150px;
        @media screen and (max-width: 600px) {
       font: normal normal 300 48px/96px Century Gothic;
        margin-top:-80px;
        line-height: 1em;
   }
    }
    p{
      font: normal normal normal 20px/25px Century Gothic;
      color: ${({ theme }) => theme.lightBlue};
      opacity:.5;
      padding:0;
      margin:0;
    }
    .mate{
       margin-top:-200px;
       @media screen and (max-width: 600px) {
           margin-top:-100px;
          }
    }
   @media screen and (max-width: 1200px) {
     .iot-img{
       display:none;
     }
   }
  }
   .systems-wrapper {
     display:flex;
     align-items:center;
     justify-content:space-between;
     margin-top:-200px;
     margin-bottom:200px;
     
     
    .outline{
    -webkit-text-stroke: .4px ${({ theme }) => theme.yellow};
    color: transparent;
    font-size: 25em;
    font-weight: 800;
    opacity:0.9;
    @media screen and (max-width: 600px) {
     font-size: 15em;
   }
    
    }
    h2{
      font: normal normal bold 157px/163px Century Gothic;
      color: ${({ theme }) => theme.yellow};
      margin-top:-3.7em;
      margin-left:25px;
      span{
        color: ${({ theme }) => theme.lightBlue};
      }
     @media screen and (max-width: 600px) {
      font: normal normal bold 46px/84px Century Gothic;
      margin-top:-7em;
   } 
    }
     p{
      font: normal normal normal 20px/26px Century Gothic;
      color: ${({ theme }) => theme.lightBlue};
      opacity:.5;
      padding:0;
      margin:0;
    }
    .iot-img{
       margin-top:19em;
     }
    @media screen and (max-width: 1200px) {
     .iot-img{
       display:none;
     }
   }
   }
  `,
};
export default Style;