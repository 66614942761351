import Styled from "styled-components";
import BgImg from "../../assets/img/contactHeroBg.png";
import VideoBg from "../../assets/img/fga.png";

const Style = {
  AboutHero: Styled.div`
 background:linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /80%)), url(${BgImg});
 background-size: cover;
 background-position: 50% 50%;
 height:100%;
 width:100%;
 
 
 .hero-wrapper{
     display:flex;
     flex-direction:column;
    
 }
.about-hero{
    height:50vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
    align-item:flex-start;
    
     
    
    h1{
        font: normal normal normal 107px/114px Century Gothic;
        color: ${({ theme }) => theme.yellow};
        span{
           color: ${({ theme }) => theme.lightBlue}; 
        }
    }
    @media screen and (max-width: 600px) {
         h1{
           font: normal normal normal 16px/88px Century Gothic;
        color: ${({ theme }) => theme.yellow};
        span{
           color: ${({ theme }) => theme.lightBlue};
        }
      }
}
    @media screen and (max-width: 600px) {
         h1{
           font: normal normal normal 36px/48px Century Gothic;;
        color: ${({ theme }) => theme.yellow};
        span{
           color: ${({ theme }) => theme.lightBlue};
        }
      }
}
 `,
  AboutSectionTwo: Styled.div`
 .container{
     display:flex;
     align-items:center;
     justify-content:space-between;
     height:50vh;
     h1{
         font: normal normal bold 58px/71px Century Gothic;;
         color: ${({ theme }) => theme.yellow};

    @media screen and (max-width: 600px) {
          
              font: normal normal bold 28px/36px Century Gothic;
              line-height:1.5em;
          
      }
        span{
           color: ${({ theme }) => theme.lightBlue}; 
     }
     }
     .outline{
    -webkit-text-stroke: .4px ${({ theme }) => theme.yellow};
    color: transparent;
    font: normal normal bold 400px/600px Century Gothic;
    font-weight: 800;
    opacity:0.5;
    transform:translateY(-5vw);
    @media screen and (max-width: 1000px) {
        display:none;
    }
}
 
 }
 }
 .quality-text{
     h1{
         font: normal normal bold 80px/83px Century Gothic;
         color: ${({ theme }) => theme.lightBlue};
         @media screen and (max-width: 600px) {
           font: normal normal bold 24px/38px Century Gothic;
    }

     }
     p{
         font: normal normal normal 30px/35px Century Gothic;
         color: ${({ theme }) => theme.lightBlue};
         padding-right:10vw;
          @media screen and (max-width: 600px) {
           font: normal normal normal 16px/24px Century Gothic;
    }
         
     }
 }
`,
  AboutVideo: Styled.div`
  height:100vh;
 .video-wrapper{
    background:linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /0%)), url(${VideoBg});
    background-size: cover;
    background-position: 50% 50%;
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
.video{
    display:flex;
    justify-content:center;
    align-items:center;
    
    .video-content{
        display:flex;
        @media screen and (max-width: 600px) {
           flex-wrap:wrap;
    }
        h1{
         font: normal normal normal 2em Century Gothic;
         color: ${({ theme }) => theme.yellow};
         
         
        }
         h1 > span{
           font-size:1em;
           font-weight:900
         }
        img{
            cursor:pointer;
        }
    }
    
}
  }
`,
  RaaxoNumbers: Styled.div`
height:70vh;
background-color: ${({ theme }) => theme.midBlue};
.numbers-wrapper{
    display:flex;
    flex-direction: column;
    align-items:center;
.countup{
    display:flex;
    align-items:center;
     color: ${({ theme }) => theme.lightBlue};
    font-size:3em;
    .count-items{
        display:flex;
        flex-direction: column;
    align-items:center;
    justify-content:space-between;
    margin:0 1em;
    @media screen and (max-width: 600px) {
           margin:0 .2em;
           }
    h5{
        font-size:.4em;
        opacity:0.4;
    }
    }
    h5{
     
     color: ${({ theme }) => theme.lightBlue};
    
}
}   
}
background-color:${({ theme }) => theme.footer};
h2{
    font-size:4vw;
    font-weight:200;
    color: ${({ theme }) => theme.yellow};
}

.stroke{
    width:4px;
    height:150px;
    background-color: ${({ theme }) => theme.lightBlue};
    transform:translateY(-2.5em);
}

`,
};
export default Style;
