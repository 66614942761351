import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Mascot } from "../../utils/icons";
import Style from "./home.styles";
import Carousel from "react-elastic-carousel";
import Footer from "../../components/footer/Footer";
import { Link } from "@reach/router";
import { useLocation } from "@reach/router";
import Typewriter from "typewriter-effect";

export default function Home() {
  const location = useLocation();

  const test = () => {
    console.log("test");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <header>
        <Navbar />
      </header>

      <Style.main>
        <Style.SectionUne
          tabIndex="-1"
          aria-hidden="true"
          className="section-data d-flex flex-direction-col"
          data-section="une"
        >
          <div className="inner-content inner-padding-content">
            <div>
              <p>Our Expertise</p>
              <h2 className="main-header" data-aos="fade-down">
                <span>
                  <Typewriter
                    options={{
                      strings: [
                        "Branding",
                        "Software Development",
                        "Digital Marketing",
                        "IOT",
                        "Consultancy",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </h2>

              <h3 data-aos="fade-up">
                We are a team of talented, dedicated IT professionals, bringing
                dreams to reality.
              </h3>
            </div>

            <div data-aos="flip-left" data-aos-easing="linear">
              <Mascot onClick={test} />
            </div>
          </div>
          <a className="down-scroll" href="#down">
            <div></div>
          </a>
        </Style.SectionUne>

        <Style.SectionDuex
          id="down"
          tabIndex="-1"
          aria-hidden="true"
          className="section-data d-flex flex-direction-col"
          data-section="deux"
        >
          <div
            className="inner-content inner-padding-content"
            data-aos="fade-up"
          >
            <h2 className="main-header">
              Solutions<span>Built to</span>
            </h2>
            <h2 className="main-header">Perfection</h2>

            <div className="info-content" data-aos="fade-up">
              <h3 className="yellow-subhead">
                About <br></br>Our Company
              </h3>
              <div className="info-content__row">
                <div className="info-content__row-item">
                  <p className="p-g mr-1">
                    We work across some of the most demanding industries,
                    providing software and system services that birth efficiency
                    in business.
                  </p>
                  <p className="p-g  mr-1">
                    We provide our clients with solutions they can trust.
                  </p>
                </div>
                <div className="info-content__row-item">
                  <p className="p-g ml-1">
                    Our technical expertise and quality standards are not the
                    only reasons we stand out. In our heart lies a culture and a
                    deeply embedded set of values that define and guide us.
                  </p>
                </div>
              </div>
              <Link to="/about" className="main-btn">
                See More
              </Link>
            </div>
          </div>
        </Style.SectionDuex>

        <Style.SectionTrios
          id="down"
          tabIndex="-1"
          aria-hidden="true"
          className="section-data d-flex flex-direction-col"
          data-section="trios"
        >
          <div className="inner-content flex-direction-col inner-padding-content">
            <div className="header-container-container" data-aos="fade-up">
              <h3 className="outline-header">Why Choose Us?</h3>
              <h3 className="header-container-container__head">
                Why Choose Us?
              </h3>
              <p>What we are</p>
            </div>

            <div className="ms-choose col-direct" data-aos="fade-up">
              <div className="ms-choose__item">
                <h4>Expertise</h4>
                <p>
                  A team of skilled specialists proffering solutions built to
                  perfection.
                </p>
              </div>

              <div className="ms-choose__item">
                <h4>Reliable</h4>
                <p>We deliver on time, we are efficient and dependable </p>
              </div>

              <div className="ms-choose__item">
                <h4>Trustworthy</h4>
                <p>
                  We assure 99.9% security on solutions and molded for our
                  clients.
                </p>
              </div>
            </div>
          </div>
        </Style.SectionTrios>

        <Style.SectionQautre
          id="down"
          tabIndex="-1"
          aria-hidden="true"
          className="section-data d-flex flex-direction-col"
          data-section="quatre"
        >
          <div className="inner-content  flex-direction-col">
            <h3 data-aos="fade-up">What We Do</h3>

            <div data-aos="fade-up" className="tile-container">
              <div className="tile-container__item title-content ">
                <div>
                  <h4>We Develop</h4>
                  <p>
                    Conceiving, specifying, designing, programming, documenting,
                    testing, and bug fixing involved in creating and maintaining
                    applications, frameworks, or other software components.
                  </p>
                  <Link to="/develop" className="main-btn">
                    See More
                  </Link>
                </div>
              </div>
              <img
                className="tile-container__item"
                src="https://res.cloudinary.com/silva/image/upload/v1605188582/fabian-irsara-67l-QujB14w-unsplash.png"
              />
            </div>
            <div data-aos="fade-up" className="tile-container">
              <img
                className="tile-container__item"
                src="https://res.cloudinary.com/silva/image/upload/v1605188615/develop.png"
              />
              <div className="tile-container__item title-content">
                <div>
                  <h4>We Brand</h4>
                  <p>
                    We build brands that inspire. We create a brand that express
                    a company personality and values by building a consistent
                    experience that resonates with customers and nurtures
                    lasting relationship.
                  </p>
                  <Link to="/branding" className="main-btn">
                    See More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Style.SectionQautre>
        <Style.SectionCinq
          id="down"
          tabIndex="-1"
          aria-hidden="true"
          className="section-data d-flex flex-direction-col"
          data-section="cinq"
        >
          <div
            className="inner-content inner-padding-content flex-direction-col"
            data-aos="fade-up"
          >
            <div className="carousel-header" data-aos="fade-up">
              <h2>What are people Saying</h2>
              <h3>Clientele</h3>
            </div>
            <Carousel
              itemsToShow={1}
              autoPlaySpeed={5000}
              showArrows={false}
              enableAutoPlay={true}
              data-aos="fade-up"
            >
              <div className="carousel-item">
                <p>
                  We build brands that inspire. We create a brand that express a
                  company personality and values by building a consistent
                  experience that resonates with customers and nurtures lasting
                  relationship.
                </p>
                <p>- chiwendu</p>
              </div>
              <div className="carousel-item">
                <p>
                  Higly skilled professionals with vast knowledge in building
                  scalable, reliable and usable software, I am pleased with
                  their workflow.
                </p>
                <p>- Juliet Esu</p>
              </div>
              <div className="carousel-item">
                <p>
                  We build brands that inspire. We create a brand that express a
                  company personality and values by building a consistent
                  experience that resonates with customers and nurtures lasting
                  relationship.
                </p>
                <p>- Chinze</p>
              </div>
            </Carousel>
          </div>
        </Style.SectionCinq>
      </Style.main>

      <Footer />
    </>
  );
}
