import Styled from "styled-components";
// import Headerbg from "../../assets/img/shutterstock_652948648@2x.png";
const Style = {
  ProjectDetails: Styled.div`
  

  .header-wrapper{
       display:flex;
       background:
        linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /25%));
        background-size: cover;
        background-position: 50% 50%;
        width:100%;
        height:70vh;
        align-items:center;
        @media (max-width: 770px) {
            height: 70vh;
          }
  }
  .project-details {
        margin-top: 2em;
        /* max-width: 56px; */

        &__header {
          display: flex;
          align-items: center;
          color: red;
          text-transform: capitalize;
           @media (max-width: 600px) {
            /* justify-content:center; */
          }

          img {
            border-radius: 50%;
            margin-right: .5em;
            width:37px;
            height:37px;
          }

          p {
            margin: 0;
            padding: 0;
            color: ${({ theme }) => theme.lightBlue};
            font-size:1.5em;
            font-weight: 600;
             @media (max-width: 600px) {
             font-size:1em;
          }
          }
        }

        &__footer {
          margin: 1.2em 0;
          display: flex;
          justify-content: space-between;
          text-transform: capitalize;

          p {
            margin: 0;
            padding: 0;
            
            &:first-child {
              font-size: 3em;
              font-weight: 700;
              max-width: 790px;
              color: ${({ theme }) => theme.yellow};
              line-height: 1.4;
               @media (max-width: 1000px) {
                font-size:1.5em;
          }
            }
          }
        }
    }
`,

  MainDetails: Styled.div`
 .main-details__wrapper{
     display:flex;
     align-items:flex-start;
     justify-content:space-between;
     @media (max-width: 1000px) {
            flex-wrap:wrap;
          }
     .main-details__left{
         h5{
             color: ${({ theme }) => theme.darkBlue};
          padding: 0;
          margin: 0;
          font-size: 1.3em;
          font-weight: 500;
          text-transform: capitalise;
         
            ::before{
              content:"";
              width:500px;
              height:20px;
              background: ${({ theme }) => theme.darkBlue};
            }
            
         }
         p{
              color: ${({ theme }) => theme.lightBlue};
            font-weight: 400;
            font-size: 2em;
             @media (max-width: 600px) {
               
               font-size:1.2em;
          }
           
         }
     }
     .main-details__right{

       
       .main-details__right__info{
         display:flex;
         justify-content:space-between;
         align-items:flex-start;
             margin-top: 2em;


        
         .serial-number{
             border:1px solid  ${({ theme }) => theme.darkBlue};
             border-radius:100%;
             width:50px;
             height:50px;
             text-align:center;
             display:flex;
             align-items:center;
             justify-content:center;
              @media (max-width: 600px) {
               width:40px;
               height:40px;
              }
                 a {
          text-decoration: none;
          color: ${({ theme }) => theme.lightBlue};
          cursor: pointer;
          font: normal normal 300 15px/25px Raleway;
          opacity:.5;
         
         
        }
             
              &:first-child {
              
              color: ${({ theme }) => theme.lightBlue};
              font-size:1.5em;
              padding: 1.5em;
              
               @media (max-width: 600px) {
               padding: 1em;
               font-size:1em;
          }
            }

            h1{
               font-weight: 300;
            }
         }
       }
       .main-details__right__description{
         h3{
            color: ${({ theme }) => theme.lightBlue};
            font-weight: 300;
            padding:0 1em;
            margin-top: 0;
            font-weight: 400;
            font-size: 1.3em;
            text-transform: capitalize;
            @media (max-width: 770px) {
             padding:0;
             font-size:1.2em;
          }
           
         }
         p{
            color: ${({ theme }) => theme.lightBlue};
            font-weight: 300;
             padding:0 1em;
              @media (max-width: 770px) {
             font-size:1.1em;
             padding:0;
          }
         }
       }
     }
     
 }
`,
  PortfolioDetails: Styled.div`

    .carousal-container {
    max-height: 350px;
    &__item {
      img {
        background: white;
        object-fit: contain;
        width: 100%;
        height: 350px;
      }
    }
  }
.portfolio-wrapper{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  margin:8em 0;
   @media (max-width: 770px) {
             flex-wrap:wrap;
             align-items:center;
          }
  .portfolio-right__img{
    
   img{
    width:40vw; 
    height:100%;
    max-height: 350px;
    object-fit: cover;
    @media (max-width: 770px) {
             width:300px; 
            height:150px;
          }
   }
  @media (max-width: 770px) {
            embed{
              width:300px; 
            height:150px;
            }
          }
   }
  }
  h1{
    font-size:3.5em;
    color: ${({ theme }) => theme.darkBlue};
    font-weight: 600;
    max-width:200px;
     @media (max-width: 770px) {
             max-width:300px;
              font-size:2.5em;
          }
  }
  h3{
    font-size:2em;
    color: ${({ theme }) => theme.darkBlue};
    font-weight: 600;
    
  }
  p{
     color: ${({ theme }) => theme.lightBlue};
            font-weight: 300;
  }
.portfolio-left__details{
  max-width:40vw;
  h1 {
    margin: 0;
  padding: 0;
  }

   h3 {
     padding: 0;
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
   }
   @media (max-width: 770px) {
             max-width:100%;
          }
}
}

`,
};

export default Style;
