import Styled from "styled-components";
import BgImg from "../../assets/img/contactHeroBg.png";

const Style = {
  HeroWrapper: Styled.div`
    background:linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /85%)), url(${BgImg});
    background-size: cover;
    background-position: 50% 50%;
    height:100%;
    width:100%;
    display:flex;
    
    `,
  ContactHero: Styled.div`
    height:100vh;
    
    
    
    
  
.contact-hero{
    
      
    h1{
        font: normal normal normal 80px/100px Century Gothic;
        color: ${({ theme }) => theme.lightBlue};
    } 
    h1 > span {
        color: ${({ theme }) => theme.yellow};
    } 
    
    p{
        color: ${({ theme }) => theme.lightBlue};
        font: normal normal normal 25px/30px Century Gothic;
    }
}
.hero-wrapper {
    
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:100vw;
    
    .down-scroll{
        margin:0 auto;
    }
}

`,
  ContactLocation: Styled.div`
.outer-circle{
    width:300px;
    height:300px;
    border-radius:50%;
    position:relative;
    border:.5px solid ${({ theme }) => theme.lightestYellow};
    /* opacity:0.5; */
    .outer-circle-text{
        h2{
            font-size:2.5vw;
        }
    }
    
    
   @media screen and (max-width: 600px) {
       .outer-circle-text{
           position:absolute;
           top:60px;
           h2{
               font-size: 5vw;
               opacity:1 !important
           }
       }
   }

.inner-circle{
    width:250px;
    height:250px;
    border-radius:50%;
    position:absolute;
    top:8%;
    left:8%;
    border:1px solid ${({ theme }) => theme.lightestYellow};
}
}
.location-wrapper{
    @media screen and (max-width: 600px) {
       
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:100px;
    }

    h2{
        font-size:3vw;
        color: ${({ theme }) => theme.yellow};
    }
    p{
        color: ${({ theme }) => theme.lightBlue};
        font-size: 1em;
        padding-left: 3em;
        
    }
}
.location{
    display:flex;
     flex-direction:column;
    justify-content:center;
    h1{
        font-size: 10vw;
        color: ${({ theme }) => theme.navyBlue};
    }

    .location-img{
        width:80%;
         @media screen and (max-width: 800px) {
             width:100%;
         }
    }
}
.address{
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    justify-content:space-around;
    padding: 5em 0;
   @media screen and (max-width: 800px) {
   justify-content:flex-start;
   padding:2em 0;
    }
    p{
        padding:0;
        font-size:1.5em;
    }
    span{
        font-size:1.5em;
    }
    .phone-number{
        display:flex;
        flex-direction:column;
        color: ${({ theme }) => theme.lightBlue};
        @media screen and (max-width: 600px) {
        margin-top:2em;
    }
}
}
.contact-form{
    display:flex;
    justify-content:space-evenly;
    flex-wrap:wrap;
     @media screen and (max-width: 600px) {
      flex-direction:column;
      .form-left{
          width:100vw;
      }
   }
    .form-left{
     width:30vw;
     @media screen and (max-width: 600px) {
         width:80vw;
      
      h2{
          font-size:2em;
      }
   }
     p{  
         padding-left:5vw;
         font-size:1.5em;
         @media screen and (max-width: 1000px) {
         padding-left:0;
      }
   }
     }
     span{
          color: ${({ theme }) => theme.lightBlue};
     }
    }
    .form {
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  input,textarea {
  padding: 20px;
  margin-bottom: 20px;
  border: none; 
  border-bottom: 1px solid ${({ theme }) => theme.placeholder};
  background: transparent;
  width:25em;
  font-size: 16px;
  color:white;
  outline: none;
  @media screen and (max-width: 1000px) {
         width:100%;
      }
  ::placeholder {
  color:${({ theme }) => theme.placeholder};
}
  
}
}

}
`,
};

export default Style;
