import Styled from "styled-components";

const Style = {
  NewsLetter: Styled.div`
margin-bottom:10em;
.newsletter-left{
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    margin-top:-100px;
    @media screen and (max-width: 1000px) {
        margin-top:100px;
        flex-wrap:wrap;
       }

    h4{
    font: normal normal bold 26px/27px Century Gothic;
    color: ${({ theme }) => theme.yellow};
    font-weight:200;
    letter-spacing:2.5px;

}
h2{
    font: normal normal normal 51px/56px Century Gothic;
    color: ${({ theme }) => theme.lightBlue};
    font-weight:400;
     @media screen and (max-width: 500px) {
      font: normal normal normal 24px/36px Century Gothic;
     }
}
p{
    font: normal normal normal 24px/26px Century Gothic;
     color: ${({ theme }) => theme.grey};
      @media screen and (max-width: 500px) {
      font: normal normal normal 16px/24px Century Gothic;
     }
}
}
h4{
    font-size:1.5em;
    color: ${({ theme }) => theme.yellow};

}
h2{
    font-size:2.5em;
    color: ${({ theme }) => theme.lightBlue};
    font-weight:400;
}
p{
    font-size:1.2em;
     color: ${({ theme }) => theme.lightGrey};
}
.outline{
    -webkit-text-stroke: .4px ${({ theme }) => theme.yellow};
    color: transparent;
    font-size: 25vw;
    font-weight: 800;
    opacity:0.5;
    @media screen and (max-width: 1000px) {
       display:none;
       }
}
.form-field {
    margin-top:-20vw;
    width:90%;
    padding:0 8vw;
    margin-left:auto;
    margin-right:auto;
     @media screen and (max-width: 1000px) {
       margin-top:-100px;
       width:100%;
       padding:2em;
       p{
           padding:0;
       }
    .check-box{
     flex-wrap:wrap;
    }
       }
   .check-box{
     display:flex;
     align-items:center;
     justify-content:space-between;
    input{
        background:transparent;
        width:50px;
        height:50px;
        border:2px solid  ${({ theme }) => theme.lightBlue};

    }
    p{
        font: normal normal normal 21px/27px Century Gothic;
        padding: 2vw;
         @media screen and (max-width: 1000px) {
             padding:0;
             font: normal normal normal 16px/24px Century Gothic;
         }
    }
 }
 
 .button{
     float:right;
     cursor:pointer;
 }
}
.email-input >input {
  padding: 20px;
  margin-bottom: 20px;
  border: none; 
  border-bottom: 1px solid ${({ theme }) => theme.placeholder};
  background: transparent;
  width:100%;
  font-size: 16px;
  color:${({ theme }) => theme.white};;
  outline: none;
  ::placeholder {
  color:${({ theme }) => theme.placeholder};
 font: normal normal normal 33px/34px Century Gothic;
  padding:2em 0;
      
}
   @media screen and (max-width: 1000px) {
       margin:40px 0;
       font: normal normal normal 16px/24px Century Gothic;
   }

}

`,
};
export default Style;
