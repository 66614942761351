import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import { Link } from "@reach/router";
import Style from "./marketing.style";
import { useLocation } from "@reach/router";

export default function Consultancy() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <header>
        <Navbar />
      </header>
      <Style.DevelopWrapper>
        <section className="header-wrapper">
          <div className="inner-padding-content">
            <Style.DevelopHeader>
              <div className="hero-item">
                <h3>Digital Marketing</h3>
                <p>
                  We develop efficient strategies to generate traffic, create awareness, and transformleads into loyal customers for the business, based on our client’s demands, needs, or budget. We put our client’s on the map. Our marketing solutions will help your company attract more customers, grow sales, and achieve her marketing objectives
                </p>
                <a href="#down" className="main-btn">
                  Explore
                </a>
              </div>
            </Style.DevelopHeader>
          </div>
        </section>
      </Style.DevelopWrapper>
      <section className="inner-padding-content" id="down">
        <Style.DevelopMain>
          <div className="main-wrapper">
            <div>
              <h2>
                <span>
                  Reach <br></br>
                </span>
                   the Right Audience
              </h2>
              <p>
                Creating a digital presence encapsulating enough to invite potential leads requires more than the bare minimum effort and random social accounts. It requires tactic, methods, plan, and utmost content. At Raaxo Synergy our Professional touch gives you a defining stance amongst the crowd. We bring you to your market, and your market to you.
              </p>
            </div>
            {/* <div className="main-left">
              <span>
                <Link to="/Iot">IOT & Embedded Systems</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Digital Marketing</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Consultancy</Link>
              </span>
            </div>
           */}
          </div>
        </Style.DevelopMain>
      </section>
      <Style.ContentWrapper>
        <main>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content title-content">
                <div className="content-left tile-container__item ">
                  <img
                    className="tile-container__item"
                    src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  ></img>
                </div>
                <div className="content-right tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>Be Visible</h2>
                    <p>
                      At Raaxo Synergy, we fuse with your business to understand your brand, quintessence and future goals. With every meeting, we seek to design a digital marketing strategy that lays the foundations for client reach, engagement and growth.

                    </p>
                    <Link to="/develop" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content">
                <div className="content-left tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>
                      Build a community or Stay Relevant</h2>
                    <p>
                      With your brand in our capable hands, we will create a digital marketing strategy which speaks your language and works perfectly for you. We will build your community, through a wide variety of tactics and expertise.
                      Digital marketing requires creativity, innovative campaign planning and inspiration. Our team of digital experts and content creators will take your brand and elevate it. Hence, delivering engaging content and results for your brand.
                    </p>
                    <Link to="/iot" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
                <div className="content-right tile-container__item">
                  <img
                    className="tile-container__item"
                    src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  ></img>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
        </main>
      </Style.ContentWrapper>
      <Style.Footer>
        <div className="footer">
          <Footer />
        </div>
      </Style.Footer>
    </>
  );
}
