import React, { useEffect } from "react";
import Style from "./about.style";
import Navbar from "../../components/navbar/Navbar";
import CountUp from "react-countup";
import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/footer/Footer";
import play from "../../assets/img/play.svg";
import { useLocation } from "@reach/router";

export default function About() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <section className="">
        <header>
          <Navbar />
        </header>
        <Style.AboutHero>
          <div className="section-data hero-wrapper">
            <div className=" about-hero inner-padding-content">
              <h1>
                We are <br></br>
                <span>Raaxo Synergy</span>{" "}
              </h1>
              <a className="main-btn" href="#down">
                Explore
              </a>
            </div>
            {/* <a className="down-scroll " href="#down" id="downScroll">
              <div></div>
            </a> */}
          </div>
        </Style.AboutHero>
      </section>
      <section className="section-2 inner-padding-content " id="down">
        <Style.AboutSectionTwo>
          <div>
            <div className="container">
              <div id="down">
                <h1>
                  Quality is at the heart{" "}
                  <span> of everything we do at Raaxo Synergy</span>
                </h1>
              </div>
              <div className="outline">
                <h2>RS</h2>
              </div>
            </div>
            <div className="quality-text">
              <h1>We are committed to</h1>
              <p>
                Providing dependable, scalable and industry standard solutions
                tailored to the needs of our clients on demand through the use
                of cutting-edge technology. Established in 2020, we provide
                branding and software services for business. We help to ensure
                our clients meet the most demanding quality standards for
                software effectiveness, performance and reliability. Our data
                management solutions and services also provide clients with the
                information they need to manage their important assets and
                processes, helping them to achieve better business performance.
              </p>
            </div>
          </div>
        </Style.AboutSectionTwo>
      </section>
      {/* <Style.AboutVideo className="inner-padding-content">
        <section className="video-wrapper ">
          <div className="video">
            <div className="video-content">
              <img src={play} alt="play"></img>
              <h1>
                See the Inside <br></br> <span>watch video</span>
              </h1>
            </div>
          </div>
        </section>
      </Style.AboutVideo>
       */}
      <Style.RaaxoNumbers>
        <section className="section-3 inner-padding-content">
          <div className="numbers-wrapper">
            <div className="stroke"></div>
            <div>
              <h2>
                Raaxo in <b>numbers</b>
              </h2>
            </div>
            <div className="countup">
              <div className="count-items">
                <div>
                  <CountUp start={0} end={20} duration={5} separator={","} />
                  <span>+</span>
                </div>
                <h5>Employees</h5>
              </div>
              <div className="count-items">
                <div>
                  {" "}
                  <CountUp start={0} end={1} duration={5} separator={","} />
                </div>
                <div>
                  <h5>Office</h5>
                </div>
              </div>

              <div className="count-items">
                <div>
                  <CountUp start={0} end={8} duration={5} separator={","} />
                  <span>+</span>
                </div>
                <h5>clients</h5>
              </div>
            </div>
          </div>
        </section>
      </Style.RaaxoNumbers>
      <Newsletter />
      <Footer />
    </>
  );
}
