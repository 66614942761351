import styled from "styled-components";
import HeaderBg from "../../assets/img/contactHeroBg.png";

const Style = {
  TeamHeader: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

    background: linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /85%)),
      url(${HeaderBg});
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    h3 {
      font-size: 75px;
      font-weight: 600;

      color: ${({ theme }) => theme.footerDarkBlue};
      @media screen and (max-width: 600px) {
        font-size: 36px;
      }
      span {
        color: ${({ theme }) => theme.yellow};
      }
    }
    .header-wrapper {
      /* height: 100vh; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }

    .down-scroll {
      margin: 0 auto;
    }
  `,
  TeamMembers: styled.div`
    h1 {
      font-size: 48px;
      font-weight: 800;
      color: ${({ theme }) => theme.yellow};
      @media screen and (max-width: 600px) {
        font-size: 40px;
        font-weight: 500;
      }
      @media screen and (max-width: 320px) {
        font-size: 24px;
      }
    }
    p {
      font-size: 26px;
      font-weight: 500;
      line-height: 1.5em;
      color: ${({ theme }) => theme.lightBlue};
      @media screen and (max-width: 600px) {
        font-size: 1.4em;
      }
      .modal {
        z-index: 1000;
      }
    }
  `,
  TeamBios: styled.div`
    display: flex;
    flex-direction: column;

    .bios-wrapper {
      display: flex;
      margin-top: 0.5em;
      flex-wrap: wrap;
      flex-basis: 400px;

      @media screen and (max-width: 600px) {
        padding-right: 0;
        justify-content: space-evenly;
      }
    }

    .bios-container {
      margin-bottom: 4%;
      margin-right: 2%;
      width: 23%;

      @media (max-width: 964px) {
        width: 46%;

        &:nth-child(even) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .bio-img {
        position: relative;
        img {
          max-width: 400px;
          width: 100%;
          max-height: 500px;
          object-fit: cover;
        }

        svg {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 5em;
          cursor: pointer;

          &:hover {
            transform: scale(0.9);
          }
          @media (max-width: 500px) {
            width: 2.5em;
          }
        }
      }

      h3 {
        color: ${({ theme }) => theme.lightBlue};
        margin: 0;
        padding: 0;
        font-size: 1.8em;
        margin-top: 0.4em;
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }
      h6 {
        color: ${({ theme }) => theme.lightBlue};
        margin: 0;
        padding: 0;
        font-size: 1.2em;
        font-weight: 400;
        line-height: 1.5;
        @media screen and (max-width: 600px) {
          font-size: 0.8em;
        }
      }
    }
  `,
};
export default Style;
