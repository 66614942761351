import React, { useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Style from "./policy.style"

import { useLocation } from "@reach/router";


export default function Policy() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div>
            <header>
                <Navbar />
            </header>
            <Style.Privacy>
            <div className="inner-padding-content">

           
            <div className="privacy-policy  ">
                <h2>POLICIES</h2>
                <p>Our website uses cookies, we use cookies for your convenience to store information so you do not have to re-enter your data on each visit. Cookies and other technologies are also used to monitor usage of the various features and services of our site, which over time gives us information to improve our site, and to advertise and market our products more effectively.
                When you visit our websites, cookies will be placed on your computer or device to maximise your experience and to help us
                understand how we can improve our site. If you prefer not to receive cookies, you can set your browser to either reject all cookies,
                to allow “trusted” sites to set them, or to accept only those cookies from those sites you are currently on. However,
                 turning off cookies will hinder your ability to access, use, or navigate portions of the site.</p>
            </div>
            <div className="information-we-collect">
                <h2>PRIVACY POLICY</h2>
                <h3>Information we collect</h3>
                <p>We obtain personal information (such as name and others) when you register on our website, subscribe to emails or respond to a survey or fill out a form.
                In addition, when you visit our website we may collect certain information by automated means, such as cookies and web beacons.  A “cookie” is a file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. A “web beacon” is a technology that can recognize specific cookies and convey information about that browser.
                We do not use these technologies to collect PII (Personally Identifiable Information).</p>
                <div className="information-use">
                    <h3>Use and sharing of information</h3>
                    <p>Our brand uses information collected for a variety of business reasons such as:
                    Personalizing and improving your experience with tailored content, information, advertisements, services, and offers (your information helps us to better respond to your individual needs).
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.
                    (if we would do this) However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                </div>
                <div className="protection">
                    <h3>How we protect your information</h3>
                     <p>We maintain appropriate administrative, technical and physical measures in place to safeguard the security of your personal information.</p>
                </div>
                <div>
                    <h3>Your Rights</h3>
                    <p>With your personally identifiable information like the information you give us to fulfill an event registration – you have the option to change or correct your information and the option of not hearing from us again. There are several ways you can control our use of your PII in connection with marketing and promotions.</p>
                    <p>To remove your name from our lists. You can choose to decline communications from us at the point that we request your PII. After we have collected your PII, you can choose to stop our communications.</p>
                    <p> stop email communications. If you receive email communications from us and want to opt out of receiving such communications in the future, you can click the “Unsubscribe” link at the bottom of the promotional email.
                   <b>(If we would do this)</b>To stop collection of geographic location. If you do not want us to collect your device’s geographic location, you can disable or opt out of that function when you first use the Site or in your device settings. This will prevent you from using some features of the Site.</p>
                </div>
                <div className="protection">
                    <h3>Interest-based Advertising (if we would do this)</h3>
                     <p>We may work with third-party advertising companies that collect and use information about your online activities across sites over time, in order to deliver more relevant advertising when you are using our services and elsewhere on the Internet. We do not operate or control this site, and are not responsible for the opt-out choices available there. Note that electing to opt out will not stop advertising from appearing in your browser or applications. It may make the ads you see less relevant to your interests. If you delete, block or otherwise restrict cookies or use a different computer or Internet browser, you may need to renew your opt-out choice.</p>
                </div>
                <div className="protection">
                    <h3>Third party links (if we would do this)</h3>
                     <p>This website may contain links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about third party sites.</p>
                </div>
                <div className="protection">
                    <h3>Changes to this Statement</h3>
                     <p>We may update our Privacy Statement from time to time. By continuing to use the services and our website, you agree to the latest version of our Privacy Statement. Any changes we make to our Privacy Statement in the future will be posted on this page and, where appropriate, notified to you by email. </p>
                </div>
                <div className="protection">
                    <h3>PRODUCT PRIVACY POLICY</h3>
                     <p>At Raaxo Synergy, we are committed to safeguarding and preserving the privacy of our product users. This Privacy Policy explains what happens to any personal data that you provide to us or that we collect from you while you use our software. </p>
                </div>
                <div className="protection">
                    <h3>PRODUCT PRIVACY POLICY</h3>
                     <p>At Raaxo Synergy, we are committed to safeguarding and preserving the privacy of our product users. This Privacy Policy explains what happens to any personal data that you provide to us or that we collect from you while you use our software. </p>
                     <h3>Chrome Extensions and Google Drive Add-On</h3>
                </div>
                <div className="protection">
                    <h3>Whisper</h3>
                     <p>Whisper utilizes token-based authentication in order to sync directly to Google Classroom. All conversational data are collected and stored in Google Cloud storage. Conversation data are only accessible by the two parties in the conversation with no access to conversation logs by Raaxo Synergy’s employees. Further, no user-identifiable information is available or accessible to Raaxo Synergy’s employees.</p>
                     <p>Data are controllable by the Google Classroom teacher. The Chrome extension has a button that allows them to clear the message history from Google Cloud servers. In addition, each message that is sent through Whisper is also sent by email to both teacher and students. Email is sent on teacher’s behalf and stored and archived in accordance with school or district email archival policies.</p>
                     
                </div>
                <div className="protection">
                    <h3>CheckMark</h3>
                     <p>Authentication for this Chrome extension is handled via OAuth. Checkmark saves a comment backup file to the user’s Google Drive for access on other devices by the same user. No user-identifiable information or data are accessible or stored by Raaxo Synergy’s employees.</p>
                     
                </div>
                <div className="protection">
                    <h3>AudioPlayer for Slides Add-on and Extension</h3>
                     <p>Authentication for this Chrome extension and the Google Drive add-on is handled via OAuth and only grants the user the ability to search their own Google Drive. No user-identifiable information or data are accessible or stored by Raaxo Synergy’s employees.</p>
                     
                </div>
                <div className="protection">
                    <h3>ScreenShade</h3>
                     <p>This extension requires no authentication to any services. No user-identifiable information or data is accessible or stored by Raaxo Synergy’s employees.</p>
                     
                </div>
                <div className="protection">
                    <h3>Scanning Content</h3>
                     <p>We do not scan any content. Our systems are not designed to associate personal information with your activities.</p>
                     
                </div>
                <div className="protection">
                    <h3>We do not share your personal data with other companies</h3>
                     <p>We do not share any personal data (such as name, address, email, or phone) with any third party.</p>
                     
                </div>
                <div className="protection">
                    <h3>No ad companies collect data through our service</h3>
                     <p>We do not display advertising, therefore, no data is collected through our software for ad targeting. We do not operate any referral program and do not display any sponsored links.</p>
                     
                </div>
                <div className="protection">
                    <h3>We take steps to protect personal information</h3>
                     <p>We take reasonable steps to secure your personally identifiable information against unauthorized access or disclosure. </p>
                     
                </div>
                <div className="">
                    <h3>Monitoring usage</h3>
                     <p>Raaxo Synergy monitors usage of the product to allow us to provide new and improved features to the User. Monitoring of the software is provided by Google Analytics which uses cookies to track usage. The software will save a cookie to your computer’s hard drive in order to track and monitor your engagement and usage of the product but will not store, save, or collect personal information. You can read Google's privacy policy here for further information http://www.google.com/privacy.html .</p>
                     
                </div>
                <div className="">
                    <h3>Google Analytics</h3>
                     <p>Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their website. Google Analytics customers can view a variety of reports about how visitors interact with their website so they can improve it. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors.</p>
                     
                </div>
                <div className="">
                    <h3>Enforcement</h3>
                     <p>We regularly review our compliance with our Privacy Policy. If you have any questions regarding this Statement or our use of personally identifiable information on this site, please send us your questions or comments as set forth in the Contact section below. (…)</p>
                     
                </div>
                
                <div>
                    <h3>Terms and Conditions</h3>
                    <ol>
                        <li>
                            <h3> Definitions</h3>
                            <ol>
                                <li>
                                    <p>“The Seller” shall mean Raaxo Synergy</p>
                                </li>
                                <li>
                                    <p>“The Buyer” shall mean the entity or person named on any form provided by the Buyer to the Seller (or any person acting on behalf of and with the authority of such entity or person).</p>
                                </li>
                                <li>
                                    <p>The Buyer shall be personally liable to the Seller in respect of all of the Conditions of Sale herein.</p>
                                </li>
                                <li>
                                    <p>Where there is more than one Buyer the covenants on their part shall be deemed to be joint and several covenants.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>Quotations</h3>
                            <p>In every situation where the Seller provides to the Buyer a quotation then</p>
                            <ol>
                                <li>
                                    <p>The acceptance of that quotation by the Buyer includes the acceptance of these Conditions of Sale except to the extent that they are in conflict with the express terms of the quotation.</p>
                                </li>
                                <li>
                                    <p> The Seller reserves the right to request a deposit of the price before the commencement of any work. This amount or percentage shall be communicated in writing.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
             </div>
             </Style.Privacy>
            <Footer />
        </div>
    )
}
