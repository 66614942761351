import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Carousel from "react-elastic-carousel";
import useFetch from "../../components/useFetch";
// import data from "../project/projectdata";
import Style from "./project.style";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader"

export default function ProjectDetails({ id }) {

  const url = `https://raaxo-console.herokuapp.com/api/v1/projects`;
  const { status, data, error } = useFetch(url);
  const mainData = data.projects;
  let foundproject;
  if (status === "fetched") {
    foundproject = mainData.find((x) => x.id === id)
    console.log(mainData, id)
    console.log("hjjkjk", foundproject)
  }


  return (
    <div>
      <Navbar />
      {status === "error" && <h2>Network Error</h2>}
      {status === "fetched" && (
        <>

          <Style.ProjectDetails>
            <div className="details">
              <div
                className="header-wrapper"
                style={{
                  backgroundImage: `linear-gradient(#040616d9, #040616), url(${foundproject.image})`,
                }}
              >
                <div className="project-details inner-padding-content">
                  <div className="project-details__header">
                    <img src={foundproject.logo} alt={foundproject.name} />
                    <p>{foundproject.name}</p>
                  </div>
                  <div className="project-details__footer">
                    <p>{foundproject.categoryName}</p>
                  </div>
                  <div className="header-btn">
                    <a href="#down" className="main-btn">
                      Explore
                </a>
                  </div>
                </div>
              </div>
            </div>

          </Style.ProjectDetails>
          )

        </>
      )}

      <Style.MainDetails>

        {status === "fetched" && (
          <div>
            {/* {mainData.map((project, index) => ( */}
            <div className="main-details inner-padding-content">
              <div className="main-details__wrapper row">
                <div className="main-details__left col-md-6">
                  <h5>Objective &mdash;</h5>
                  <p>{foundproject.objective}</p>
                </div>

                <div className="main-details__right col-md-6" >
                  {foundproject.package.map((e, i) => (
                    <div >
                      {e.branding ? (
                        <div className="main-details__right__info" >
                          <div className="serial-number">
                            <a href="#brand"><h1>{i + 1}</h1></a>
                          </div>
                          <div className="main-details__right__description">
                            <h3>{e.branding.title}</h3>
                            <p>{e.branding.description}</p>
                          </div>
                        </div>
                      ) : null}

                      {e?.software ? (
                        <div className="main-details__right__info" >
                          <div className="serial-number">
                            <a href="#software"><h1>{i + 2}</h1></a>
                          </div>
                          <div className="main-details__right__description">
                            <h3>{e?.software?.title}</h3>
                            <p>{e?.software?.description}</p>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  ))}


                </div>
              </div>
            </div>
            {/* ))} */}
          </div>
        )}

      </Style.MainDetails>
      <Style.PortfolioDetails>

        {status === "fetched" && (
          <>

           
            <div className="portfolio-main-wrapper inner-padding-content">
              <div className="portfolio-wrapper row">
                <div className="portfolio-left__details col-md-6" id="brand">
                  <h1>{foundproject?.branding?.title}</h1>
                  <p>{foundproject?.branding?.description}</p>
                </div>
                {foundproject?.branding ? (
                  <div className="col-md-6">
                    {/* return ( */}

                    <Carousel
                      itemsToShow={1}
                      autoPlaySpeed={5000}
                      showArrows={true}
                      enableAutoPlay={true}
                      data-aos="fade-up"
                      className="carousal-container"
                    >
                      {foundproject?.branding?.projects.map((e, i) => (
                        <div className="carousal-container__item" key={e.id}>
                          <img src={e.image} alt={e.name} alt="" srcset="" />
                        </div>
                      ))}
                    </Carousel>
                    {/* ); */}
                    {/* </Carousel> */}
                  </div>
                ) : null}
              </div>

              <div className="portfolio-wrapper" id="software">
                <div className="portfolio-left__details">
                  <h1>{foundproject?.software?.title}</h1>
                  <p>{foundproject?.software?.description}</p>
                </div>
                <div className="portfolio-right__img">
                  <img src={foundproject?.software?.image}></img>
                </div>
              </div>
              {foundproject?.software?.projects.map((e, i) => (
                <div className="portfolio-wrapper" key={e.id}>

                  <div className="portfolio-left__details" >
                    <h3>
                      {e.title} &mdash;
                  </h3>
                    <p>
                      {e.description}
                    </p>
                  </div>
                  <div className="portfolio-right__img">
                    <img
                      src={e.image}
                    ></img>
                  </div>
                </div>
              ))}



            </div>


          </>
        )}
        {status === "fetching" && <Loader />}
      </Style.PortfolioDetails>
      <Footer />
    </div>
  );
}
