import styled from "styled-components";

const Style = {
  Nav: styled.nav`
    display: flex;
    justify-content: space-between;
    position: fixed;
    margin: 0 !important;
    width: 100%;
    align-items: center;
    z-index: 1000;
    transition: 0.5s all;

    @media screen and (min-width: 1420px) {
      min-height: 7.5em;
    }

    @media screen and (min-width: 600px) {
      height: 4.7em;
    }

    @media screen and (max-width: 600px) {
      height: 6em;
    }

    .i-first {
      transform: rotate(-45deg) translateX(-4px) translateY(8px);
    }
    .i-last {
      transform: rotate(45deg) translateX(-3px) translateY(-6px);
    }

    .navbar-toggler {
      outline: none;
      border: 0;
      background-color: transparent;

      &:hover {
        .icon-bar:nth-child(2) {
          transform: translateX(10px);
        }
      }

      .icon-bar {
        border: none;
        background: ${({ theme }) => theme.lightBlue};
        height: 2.5px;
        width: 24px;
        border-radius: 1px;
        cursor: pointer;
        display: block;
        margin: 5px;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }
    }

    .feather-search {
      color: ${({ theme }) => theme.lightBlue};
      cursor: pointer;
    }
  `,

  NavSidebar: styled.div`
    position: fixed;
    background: ${({ theme }) => theme.background};
    top: 0;
    height: 100%;
    right: 0;
    top: 0;
    padding-top: 5em;
    transition: 0.6s;
    margin: 0 !important;
    z-index: 100;
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      /* display: flex;
      justify-content: space-between; */

      li {
        padding: 0.3em 0 0.3em 0;
        color: ${({ theme }) => theme.lightBlue};
        font-weight: 500;
        margin: 0.7em 0;
        font-size: 0.89em;

        a {
          text-decoration: none;
          color: ${({ theme }) => theme.lightBlue};
          cursor: pointer;
          font-weight: 300;
          font-size: 0.89em;
        }
      }
    }
    .menu {
      display: none;
      align-items: center;
      height: 100%;
      display: flex;
      position: relative;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .menu-items {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        margin-top: 30px;
        flex-wrap: wrap;
        height: 100%;
        align-content: center;
        top: -10%;

        @media screen and (max-width: 400px) {
          top: 30%;
        }
        .home {
          margin: auto;
          position:relative;
          top:6.5em;
        }
        .menu-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: wrap;
          opacity: 0;
          transform: translateY(80px);
          animation: Fade-In 1.8s ease forwards;
          color: ${({ theme }) => theme.lightBlue};

          &:nth-child(1) {
            animation-delay: 0.8s;
          }

          &:nth-child(2) {
            animation-delay: 1.2s;
          }

          &:nth-child(3) {
            animation-delay: 1.4s;
          }

          &:nth-child(4) {
            animation-delay: 1.8s;
          }

          h4 {
            font-size: 1.2em;
          }

          a {
            text-decoration: none;
            color: ${({ theme }) => theme.lightBlue};
            cursor: pointer;
            font-weight: 300;
            margin: 0.7em 0;
            font-size: 0.89em;
            animation: Fade-In 2s;
            animation-direction: forwards;
            animation-timing-function: ease-in;
            animation-delay: 0.2s;
            transition: 0.5s ease-in-out;

            &:hover {
              transform: translateX(10px);
              cursor: pointer;
              transition-timing-function: ease-in-out;
            }
          }
        }
      }

      @keyframes Fade-In {
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  `,
};

export default Style;
