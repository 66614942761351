import React from "react";
import Style from "./footer.styles";
import { Link } from "@reach/router";
import { Logo } from "../../utils/icons";
import useFetch from "../../components/useFetch";

// import { _.map, tail, times, _.uniq } from 'lodash';

export default function Footer() {
  const [modalShow, setModalShow] = React.useState(false);
  const url = `https://raaxo-console.herokuapp.com/api/v1/projects`;
  const { status, data, error } = useFetch(url);
  const mainData = data.projects;
  let result;
  let catArr = new Set();
  let uniqueArr = [];
  let catobj = [];
  if (status === "fetched") {
    console.log(mainData.map((project) => project.category[0]));
    //  result = _.uniq(_.map(mainData, item => item.algorithm))


  }

  const removeDupliactes = (values) => {
    let concatArray = values.map(eachValue => {
      return Object.values(eachValue).join('')
    })
    let filterValues = values.filter((value, index) => {
      return concatArray.indexOf(concatArray[index]) === index

    })
    return filterValues
  }
  return (
    <Style.Footer>
      <Style.FooterHeader className="inner-padding-content col-direct">
        <h3 data-aos="slide-right">
          Solutions built to <br></br> <span>Perfection</span>
        </h3>
        <Link to="/" data-aos="slide-right">
          <Logo />
        </Link>
      </Style.FooterHeader>

      <Style.FooterMain className="inner-padding-content col-direct">

        {status === "fetched" && (
          <div className="footer__item">
            <h4>Our Projects</h4>
            {mainData.map((item, i) => (
              item.category.map((cat) => {
                let thecat = cat.name;

                catArr.add(thecat)
              })
            ))}




            <ul>
              {
                Array.from(catArr).map((item, i) => {
                  return (
                    <li>
                      <Link to="/project">{item}({Array.from(catArr).length}) </Link>{" "}
                    </li>
                  )
                })
              }


            </ul>
          </div>

        )}

        <div className="footer__item">
          <h4>Expertise</h4>
          <ul>
            <li>
              <Link to="/develop">Software Development</Link>
            </li>
            <li>
              <Link to="/marketing">Digital Marketing</Link>
            </li>
            <li>
              <Link to="/iot">IOT & Embedded Systems</Link>
            </li>
            <li>
              <Link to="/branding">Branding</Link>
            </li>

            <li>
              <Link to="/consultancy">Consultancy</Link>
            </li>
          </ul>
        </div>

        <div className="footer__item">
          <h4>About Us</h4>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/team">Our Team</Link>
            </li>
          </ul>
        </div>

        <div className="footer__item">
          <h4>Contact Us</h4>
          <ul>
            <li>No 7, Madiana close, Wuse 2, Abuja FCT</li>
            <li>
              Phone: <a href="tel:+2349012736732">+234 901 273 6732</a>
            </li>
            <li>
              Phone: <a href="tel:+2348182798166">+234 812 782 0880</a>
            </li>
            <li>
              Email: <a href="mailto:info@raaxo.ng">info@raaxo.ng</a>
            </li>

            <li>
              Facebook:{" "}
              <b>
                <a href="https://facebook.com/raaxosynergy/">@raaxosynergy</a>
              </b>
            </li>

            <li>
              Twitter:{" "}
              <b>
                <a href="https://twitter.com/raaxosynergy">@raaxosynergy</a>
              </b>
            </li>

            <li>
              Instagram:{" "}
              <b>
                <a href="https://www.instagram.com/raaxosynergy/">
                  @raaxosynergy
                </a>
              </b>
            </li>

            <li>
              <Link to="/contact">contact us</Link>
            </li>
          </ul>
        </div>

        <div className="footer__item">
          <h4>What's new</h4>
        </div>
      </Style.FooterMain>

      <Style.FooterLegal>
        <div className="divider"></div>
        <ul className="col-direct inner-padding-content">
          <li>
            <h4>Legal</h4>
          </li>
          <li>
            <Link to="/">Career</Link>
          </li>
          <li>
            <Link to="/policy">privacy policy</Link>
          </li>
          <li>
            <Link to="/">Terms & conditions</Link>
          </li>
        </ul>
        <div className="divider"></div>
      </Style.FooterLegal>

      <Style.FooterFoot className="inner-padding-content ">
        <p>@{new Date().getFullYear()} Raaxo Synergy All rights reserved</p>
      </Style.FooterFoot>
    </Style.Footer>
  );
}
