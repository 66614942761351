import styled from "styled-components";
import Cursor from "../../assets/img/darkcursor.svg";
import BacgroundImage from "../../assets/img/animated_bg3.gif";
const Style = {
  main: styled.main``,

  SectionCinq: styled.section`
    .inner-content {
      display: flex;
      width: 100%;

      h3 {
        font-size: 4vw;
        font-weight: 800;
        color: ${({ theme }) => theme.darkBlue};
        text-align: center;
      }

      .carousel-header {
        text-align: center;
        margin-top: 3em;
        margin-bottom: 3.5em;

        h2 {
          color: ${({ theme }) => theme.yellow};
          font-size: 1.8em;
          font-weight: 400;
          line-height: 1.2;
          margin: 0;
        }

        h3 {
          color: ${({ theme }) => theme.lightBlue};
          font-size: 4vw;
          font-weight: 700;
          margin: 0;
        }
      }

      .carousel-item {
        max-width: 462px;
        font-weight: 400;
        text-align: center;
        p {
          &:first-child {
            color: ${({ theme }) => theme.lightBlue};
            margin-bottom: 2em;
            font-size: 1.3em;
          }

          &:last-child {
            color: ${({ theme }) => theme.yellow};
            font-size: 1.13em;
          }

          @media screen and (max-width: 570px) {
            &:first-child {
              margin-bottom: 1.5em;
              font-size: 1em;
            }

            &:last-child {
              color: ${({ theme }) => theme.yellow};
              font-size: 0.8em;
            }
          }
        }
      }

      button.rec-dot.rec-dot_active {
        background-color: ${({ theme }) => theme.yellow};
        box-shadow: none;
      }

      button.rec-dot {
        background-color: ${({ theme }) => theme.lightBlue};
        box-shadow: none;
        margin-top: 1.3em;
        margin-left: 0.3em;
        margin-right: 0.3em;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }

      button.rec-dot:hover,
      button.rec-dot:active,
      button.rec-dot:focus {
        background-color: ${({ theme }) => theme.yellow};

        box-shadow: none;
      }
    }
  `,

  SectionQautre: styled.section`
    .inner-content {
      display: flex;
      width: 100%;

      h3 {
        font-size: 4vw;
        font-weight: 800;
        color: ${({ theme }) => theme.darkBlue};
        text-align: center;
      }
      .m-bt5 {
        margin: 5em 0;
      }
      .tile-container {
        display: flex;

        .tile-container__item {
          width: 50%;
          /* height: 500px; */
          object-fit: cover;

          &.title-content {
            div {
              &:first-child {
                max-width: 500px;
                margin: 0 auto;
                padding-left: 3em;
                h4 {
                  font-size: 2.5em;
                  color: ${({ theme }) => theme.yellow};
                }

                p {
                  color: ${({ theme }) => theme.lightBlue};
                  font-size: 1.3em;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 378px) {
        h3 {
          font-size: 12vw !important;
          margin: 0;
        }
      }

      @media screen and (max-width: 828px) {
        h3 {
          font-size: 9vw;
        }

        .tile-container {
          img {
            display: none;
          }
          padding: 0 2.5em;

          .tile-container__item {
            &.title-content {
              padding-bottom: 20px;
              width: 100%;
              div {
                &:first-child {
                  margin: 0;
                  padding-left: initial;
                  h4 {
                    font-size: 1.5em;
                    margin: 1em 0 0.5em 0;
                  }

                  p {
                    margin: 0;
                    font-size: 1em;
                    margin-bottom: 0.5em;
                  }
                }
              }
            }
          }
        }
      }
    }
    .highlights-wrapper {
      display: flex;
      align-items: center;
      height: auto;
      width: 100%;
      margin-top: 200px;
      .highlights-container {
        display: flex;
        flex-direction: column;
        padding: calc(154rem * 0.0625) 0rem;
        padding-bottom: 6rem;
        .highlight-one {
          display: flex;
          justify-content: center;
          width: 100%;
          overflow: visible;
          padding-left: 2rem;
          .globe {
            height: calc(600rem * 0.0625);
            width: calc(600rem * 0.0625);
            background-image: url("../images/globe_mono.svg");
            background-size: contain;
            background-repeat: no-repeat;
            border: 4px dashed ${({ theme }) => theme.lightBlue};
            border-radius: 50%;
            .globe img {
              position: absolute;
              width: auto;
              transition: all ease 200ms;
            }
            .globe-main {
              display: flex;
              flex-direction: column;
              align-items: center;
              /* height: calc(366.33rem * 0.0625); */
              transform: translate(00px, -680px);
              z-index: 1;
              h2 {
                font: normal normal bold 49px/58px Century Gothic;
                color: ${({ theme }) => theme.lightBlue};
              }
              p {
                color: ${({ theme }) => theme.lightBlue};
                font: normal normal normal 25px/29px Century Gothic;
                margin-top: -30px;
              }
            }
            .research {
              /* height: calc(155.09rem * 0.0625); */
              transform: translate(0px, -150px);
              z-index: 2;
              display: flex;
              flex-direction: column;
              align-items: center;
              h2 {
                color: ${({ theme }) => theme.yellow};
                opacity: 0;
              }

              &:hover {
                transform: scaleY(1.5);
                transform: translate(0px, -163px);
                z-index: 3;
                h2 {
                  opacity: 1;
                }
                transition: 0.5s ease-in-out;
              }
            }
            .planning {
              /* height: calc(189.34rem * 0.0625); */
              transform: translate(490px, -120px);
              z-index: 2;
              display: flex;
              align-items: center;
              h2 {
                color: ${({ theme }) => theme.yellow};
                opacity: 0;
              }
              &:hover {
                /* height: calc(247.34rem * 0.0625); */
                transform: translate(510px, -120px);
                z-index: 3;
                h2 {
                  opacity: 1;
                }
                transition: 0.5s ease-in-out;
              }
            }
            .deployment {
              /* height: calc(189.63rem * 0.0625); */
              transform: translate(-200px, -280px);
              z-index: 2;
              display: flex;
              align-items: center;
              h2 {
                color: ${({ theme }) => theme.yellow};
                opacity: 0;
              }
              &:hover {
                /* height: calc(247.34rem * 0.0625); */
                transform: translate(-180px, -280px);
                z-index: 3;
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 1;
                }
                transition: 0.5s ease-in-out;
              }
            }

            .analyze {
              /* height: calc(189.61rem * 0.0625); */
              transform: translate(450px, -160px);
              z-index: 2;
              display: flex;
              align-items: center;
              h2 {
                color: ${({ theme }) => theme.yellow};
                opacity: 0;
              }
              &:hover {
                /* height: calc(247.61rem * 0.0625); */
                transform: translate(500px, -160px);
                z-index: 3;
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 1;
                }
                transition: 0.5s ease-in-out;
              }
            }

            .implementation {
              /* height: calc(189.36rem * 0.0625); */
              transform: translate(-180px, -330px);
              z-index: 2;
              display: flex;
              align-items: center;
              h2 {
                color: ${({ theme }) => theme.yellow};
                opacity: 0;
              }
              &:hover {
                /* height: calc(247.36rem * 0.0625); */
                transform: translate(-220px, -330px);
                z-index: 3;
                transition: 0.5s ease-in-out;
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      @media (max-width: 767.89px) {
        .highlights-container {
          padding-top: 5rem;
          padding-bottom: 2rem;
          .highlight-one {
            justify-content: center;
            padding-left: 0rem;
            .globe {
              height: calc(400rem * 0.0625);
              width: calc(400rem * 0.0625);

              .research {
                /* height: calc(155.09rem * 0.0625); */
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: center;

                transform: translate(-10px, -160px) scale(0.8);
              }
              .planning {
                /* height: calc(189.34rem * 0.0625); */
                z-index: 2;
                display: flex;
                align-items: center;
                transform: translate(290px, -180px) scale(0.8);
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 0;
                }
                &:hover {
                  /* height: calc(247.34rem * 0.0625); */
                  transform: translate(290px, -180px);
                  z-index: 3;
                  h2 {
                    opacity: 1;
                  }
                  transition: 0.5s ease-in-out;
                }
              }
              .deployment {
                /* height: calc(189.63rem * 0.0625); */
                z-index: 2;
                display: flex;
                align-items: center;
                transform: translate(-220px, -350px) scale(0.8);
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 0;
                }
                &:hover {
                  /* height: calc(247.34rem * 0.0625); */
                  transform: translate(-220px, -350px);
                  z-index: 3;
                  h2 {
                    color: ${({ theme }) => theme.yellow};
                    opacity: 1;
                  }
                  transition: 0.5s ease-in-out;
                }
              }

              .analyze {
                /* height: calc(189.61rem * 0.0625); */
                transform: translate(450px, -160px);
                z-index: 2;
                display: flex;
                align-items: center;
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 0;
                }
                &:hover {
                  /* height: calc(247.61rem * 0.0625); */
                  transform: translate(500px, -160px);
                  z-index: 3;
                  h2 {
                    color: ${({ theme }) => theme.yellow};
                    opacity: 1;
                  }
                  transition: 0.5s ease-in-out;
                }
              }

              .implementation {
                /* height: calc(189.36rem * 0.0625); */
                transform: translate(-180px, -330px);
                z-index: 2;
                display: flex;
                align-items: center;
                h2 {
                  color: ${({ theme }) => theme.yellow};
                  opacity: 0;
                }
                &:hover {
                  /* height: calc(247.36rem * 0.0625); */
                  transform: translate(-220px, -330px);
                  z-index: 3;
                  transition: 0.5s ease-in-out;
                  h2 {
                    color: ${({ theme }) => theme.yellow};
                    opacity: 1;
                  }
                }
              }
              .globe-main {
                /* height: calc(175.33rem * 0.0625); */
                transform: translate(0px, -760px);
                h2 {
                  font: normal normal bold 38px/38px Century Gothic;
                  color: ${({ theme }) => theme.lightBlue};
                }
                p {
                  color: ${({ theme }) => theme.lightBlue};
                  font: normal normal normal 25px/29px Century Gothic;
                  margin-top: -30px;
                }
              }
            }
          }
        }
      }
    }
  `,

  SectionDuex: styled.section`
    .inner-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      h2 {
        text-align: center;
        &:first-child {
          transform: translateX(-150px);
        }

        &:last-child {
          transform: translateX(150px);
        }

        @media screen and (max-width: 790px) {
          text-align: initial;
          &:first-child {
            transform: translateX(0) !important;
          }

          transform: translateX(80px) !important;
        }
      }

      span {
        color: ${({ theme }) => theme.yellow};
        font-weight: 300;
        font-size: 0.24em;
        word-break: inherit;
        display: inline-block;
        width: min-content;
        align-items: last baseline;
        margin-left: 0.5em;

        @media screen and (max-width: 790px) {
          font-size: 0.3em;
          font-weight: 400;
        }
      }

      .info-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 3em;
        padding-right: 3em;
        max-width: calc(100vw - 19vw);
        margin: 0 auto;

        @media screen and (max-width: 1100px) {
          padding-left: 0em;
          padding-right: 0em;
        }

        h3 {
          margin-top: 2.5em;
          margin-bottom: 0.5em;
        }

        .info-content__row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media screen and (max-width: 1000px) {
            flex-direction: column;
          }

          .ml-1 {
            margin-left: 0em;
          }

          .info-content__row-item {
            flex-basis: 50%;
            p {
              max-width: 700px;
            }
            @media screen and (min-width: 1001px) {
              width: 50%;
              max-width: 1115px;
            }

            @media screen and (max-width: 992px) {
              &:last-child {
                &:last-child {
                  margin-bottom: 0.9em !important;
                }
              }
              p {
                font-size: 1em;
                margin: 0.5em 0 0 0 !important;
              }
            }
          }
        }
      }
    }
  `,

  SectionUne: styled.section`
    background: linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /35%)),
      url(${BacgroundImage});
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;

    .inner-content {
      display: flex;
      width: 100%;

      p {
        font-size: 4vw;
        font-weight: 500;
        line-height: 1.2;
        color: ${({ theme }) => theme.lightBlue} !important;
        padding: 0;
        margin: 0;

        @media screen and (max-width: 993px) {
          font-size: 8vw !important;
        }

        @media screen and (max-height: 800px) {
          font-size: 3vw !important;
        }
      }

      @media screen and (min-width: 600px) {
        margin-top: 15em;
      }

      @media screen and (max-width: 600px) {
        margin-top: 5em;
      }

      @media screen and (max-height: 800px) {
        margin-top: 8em;
      }

      div {
        &:first-child {
          flex-basis: 100vw;
          span {
            color: #feff95;
          }

          h2 {
            font-size: 6vw;
            font-weight: 700;
            line-height: 1.2;
            margin: 0;
          }

          @media screen and (max-width: 800px) {
            h2 {
              font-size: 9vw;
            }
          }

          h3 {
            color: ${({ theme }) => theme.yellow};
            margin-top: 2.2em;
            font-size: 1.8em;
            font-weight: 400;
            max-width: 500px;
          }

          @media screen and (max-width: 320px) {
            h3 {
              font-size: 0.9em !important;
            }
          }

          @media screen and (max-width: 600px) {
            h3 {
              font-size: 1.1em !important;
            }
          }

          @media screen and (max-width: 1408px) {
            h3 {
              font-size: 1.7em;
            }
          }

          @media screen and (max-width: 1218px) {
            h3 {
              font-size: 1.5em;
            }
          }
        }
      }
    }

    #mascot-side-svg {
      transform: translate3d(270.756px, 81.83px, 60px);
      transition: 0.5s ease-in-out;
    }

    .raaxo-mascot {
      /* transition: .5s ease-in-out; */
      cursor: url(${Cursor}), auto;
      width: 100%;
      min-width: 450px;
      max-height: 500px;

      &:hover #mascot-side-svg {
        transform: translate3d(290px, 90px, 60px);
        isolation: isolate;
      }

      #mascot-side-svg {
        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 15.887px 29.88px;
        animation-play-state: paused;
      }

      @media screen and (max-width: 1408px) {
        height: 400px;
      }

      @media screen and (max-width: 1218px) {
        height: 300px;
      }

      @media screen and (max-width: 1355px) {
        width: calc(100vw - 830px);
      }

      @media screen and (max-width: 1230px) {
        display: none;
      }
    }

    .down-scroll {
      width: 28px;
      height: 68px;
      border-radius: 2em;
      border: 1px solid ${({ theme }) => theme.lightBlue};
      position: relative;
      cursor: pointer;
      /* top: calc(100vh - 497px); */

      div {
        width: 12px;
        height: 28px;
        background: ${({ theme }) => theme.lightBlue};
        border-radius: 1em;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        animation: move-top-bottom 1s infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in;
        cursor: pointer;
      }

      @keyframes move-top-bottom {
        0% {
          top: 10px;
        }
        100% {
          top: 30px;
        }
      }

      /* @media screen and (max-width: 1367px) {
        top: calc(100vh - 497px);

      } */
    }
  `,

  SectionTrios: styled.section`
    .inner-content {
      display: flex;
      width: 100%;

      .ms-choose {
        display: flex;
        max-width: 900px;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        margin-top: 7em;

        .ms-choose__item {
          max-width: 170px;
          h4 {
            font-size: 1.5em;
            color: ${({ theme }) => theme.yellow};
          }

          p {
            color: ${({ theme }) => theme.lightBlue};
            font-size: 0.95em;
          }
        }
      }

      @media screen and (max-width: 560px) {
      }

      @media screen and (max-width: 992px) {
        .ms-choose {
          margin-top: 0;

          .ms-choose__item {
            text-align: center;
            margin: 0 auto;

            h4 {
              margin: 1em 0 0.25em 0 !important;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  `,
};

export default Style;
