import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import { Link } from "@reach/router";
import Style from "./develop.style";
import { useLocation } from "@reach/router";

export default function Develop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <header>
        <Navbar />
      </header>
      <Style.DevelopWrapper>
        <section className="header-wrapper">
          <div className="inner-padding-content">
            <Style.DevelopHeader>
              <div className="hero-item">
                <h3>We Develop</h3>
                <p>
                  The world is a very tech-savvy one, and that’s no different when it comes to work and business. Software is needed in almost every industry, in every business, and for every function. We analyze our client's needs and desires; design, develop, and test solutions to meet those requirements, building web and mobile applications ranging from enterprise, web portals, e-commerce, and Industry-specific business solutions for Windows and Mac OS.
                </p>
                <a href="#down" className="main-btn">
                  Explore
                </a>
              </div>
            </Style.DevelopHeader>
          </div>
        </section>
      </Style.DevelopWrapper>
      <section className="inner-padding-content" id="down">
        <Style.DevelopMain>
          <div className="main-wrapper">
            <div>
              <h2>
                <span>
                  Leverage  <br></br>
                </span>{" "}
                our expertise
              </h2>
              <p>
                With our vast expertise in developing user-friendly and scalable software solutions, we have processes designed to make custom applications that fit client’s needs; ranging from Android to iOS and windows. We help organizations become a lasting success in the global arena by solutions of different complexity.

              </p>
            </div>
            {/* <div className="main-left">
              <span>
                <Link to="/Iot">IOT & Embedded Systems</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Digital Marketing</Link>
              </span>
              <span>
                {" "}
                <Link to="/"> Consultancy</Link>
              </span>
            </div>
           */}
          </div>
        </Style.DevelopMain>
      </section>
      <Style.ContentWrapper>
        <main>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content title-content">
                <div className="content-left tile-container__item ">
                  <img
                    className="tile-container__item"
                    src="https://res.cloudinary.com/silva/image/upload/v1607297657/markus-spiske-4T5MTKMrjZg-unsplash_1_-min.jpg"
                    alt=""
                  ></img>
                </div>
                <div className="content-right tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>Proffer cheap thrills</h2>
                    <p>
                      Excite your users with feature-rich iOS / Android, cross-platform or Progressive Web Apps leveraging our extensive experience of working with major technologies.
                    </p>
                    <Link to="/develop" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
          <section className="">
            <Style.DevelopContent>
              <div className="iot-content">
                <div className="content-left tile-container__item ">
                  <div className="inner-padding-content">
                    <h2>Our modus operandi</h2>
                    <p>
                      We employ the best development methodologies for building effective software solutions in a structured and systematic way, increasing your productivity and efficiency.
                    </p>
                    <Link to="/iot" className="main-btn">
                      See More
                    </Link>
                  </div>
                </div>
                <div className="content-right tile-container__item">
                  <img
                    className="tile-container__item"
                    src="https://res.cloudinary.com/silva/image/upload/v1607297651/living-smarter-jZ2Pok_St8A-unsplash-min.jpg"
                    alt=""
                  ></img>
                </div>
              </div>
            </Style.DevelopContent>
          </section>
        </main>
      </Style.ContentWrapper>
      <Style.Footer>
        <div className="footer">
          <Footer />
        </div>
      </Style.Footer>
    </>
  );
}
