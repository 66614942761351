import { Link } from "@reach/router";
import React, { useState, useEffect } from "react";
import { Logo, Home, Search } from "../../utils/icons";
import Style from "./navbar.styles";
import { useLocation } from "@reach/router";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isOpen = () => {
    setOpen(!open);
    console.log(open);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [nav]);

  return (
    <div>
      <Style.Nav
        style={
          nav
            ? {
                background: "rgba(4, 6, 22, 0.8)",
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 41px",
                height: "4em",
              }
            : { background: "transparent" }
        }
        className="inner-padding-content"
      >
        <Link to="/" onClick={isOpen}>
          <Logo style={{ display: open ? "none" : "block" }} />
        </Link>

        <div>
          {/* {open ? <Persona className="mr-1" /> : null} */}

          {/* <Search /> */}

          <button className="navbar-toggler" onClick={isOpen}>
            <div className="icon-wrapper">
              <span className={`icon-bar ${open ? "i-first" : ""}`}> </span>
              <span
                className="icon-bar"
                style={{ opacity: open ? "0" : "" }}
              ></span>
              <span className={`icon-bar ${open ? "i-last" : ""}`}></span>
            </div>
          </button>
        </div>
      </Style.Nav>

      <Style.NavSidebar
        className={`${open ? "inner-padding-content" : ""}`}
        id="nav"
        style={{ width: open ? "100%" : "0" }}
      >
        <div className="menu" style={{ display: open ? "block" : "none" }}>
          <div className="menu-items">
            <div className="menu-content col-md-3 col-sm-12">
              <h4>Our Projects</h4>
              <Link to="/project" onClick={isOpen}>
                Software
              </Link>
              <Link to="/project" onClick={isOpen}>
                Branding
              </Link>
            </div>
            <div className="menu-content col-md-3 col-sm-12">
              <h4>Expertise</h4>
              <Link to="/branding" onClick={isOpen}>
                Branding
              </Link>
              <Link to="/iot" onClick={isOpen}>
                IOT & Embedded Systems
              </Link>{" "}
              <Link to="/develop" onClick={isOpen}>
                Software Development
              </Link>
              <Link to="/marketing" onClick={isOpen}>
                Digital Marketing
              </Link>
              <Link to="/consultancy" onClick={isOpen}>
                Consultancy
              </Link>
            </div>
            <div className="menu-content col-md-3 col-sm-12">
              <h4>About Us</h4>
              <Link to="/about" onClick={isOpen}>
                About
              </Link>
              <Link to="/team" onClick={isOpen}>
                Our Team
              </Link>
            </div>
            <div className="menu-content col-md-3 col-sm-12">
              <h4>Contact Us</h4>
              <ul>
                <li>No 7, Madiana close, Wuse 2, Abuja FCT</li>
                <li>
                  Phone: <a href="tel:+2349012736732">+234 901 273 6732</a>
                </li>
                <li>
                  Phone: <a href="tel:+2348182798166">+234 812 782 0880</a>
                </li>
                <li>
                  Email: <a href="mailto:info@raaxo.ng">info@raaxo.ng</a>
                </li>
                <li>
                  Facebook:{" "}
                  <b>
                    <a href="https://facebook.com/raaxosynergy/">
                      @raaxosynergy
                    </a>
                  </b>
                </li>

                <li>
                  Twitter:{" "}
                  <b>
                    <a href="https://twitter.com/raaxosynergy">@raaxosynergy</a>
                  </b>
                </li>

                <li>
                  Instagram:{" "}
                  <b>
                    <a href="https://www.instagram.com/raaxosynergy/">
                      @raaxosynergy
                    </a>
                  </b>
                </li>
                <li>
                  <Link to="/contact" onClick={isOpen}>
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="home">
              <Link to="/" onClick={isOpen}>
                {" "}
                <Home />{" "}
              </Link>
            </div>
          </div>
          {/* <div className="menu-content">
            <Link to="/contact">
              {" "}
              <h4>Contact Us</h4>
            </Link>
          </div> */}
        </div>
      </Style.NavSidebar>
    </div>
  );
}
