import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Style from "./team.style";
import Footer from "../../components/footer/Footer";
import useFetch from "../../components/useFetch"; 



import TeamModal from "../../components/teamModal/TeamModal";
import { useLocation } from "@reach/router";
import { TeamIcon } from "../../utils/icons";
import Loader from "../../components/loader/Loader";

export default function Team() {
  const url = `https://raaxo-console.herokuapp.com/api/v1/teams`;
  const { status, data, error } = useFetch(url);
  let mainData = data.body;

  if (status === "fetched") {
    mainData = data.body.teams;
    console.log("mainData", mainData);
  }
  const location = useLocation();
  const [showTeamMember, setshowTeamMember] = useState([]);
  const [showTeam, setshowTeam] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const toggleSidebar = () => {
    setshowTeam(!showTeam);
  };

  return (
    <>
      <header>
        <Navbar />
      </header>
      <section>
        <Style.TeamHeader>
          <div className="header-wrapper section-data">
            <div className="inner-padding-content" data-aos="fade-up">
              <div>
                <h3>
                  More than a team, <br></br> We are <span>Family</span>
                </h3>
              </div>
              <a className="main-btn" href="#down">
                {" "}
                Explore{" "}
              </a>
            </div>
          </div>
        </Style.TeamHeader>
      </section>
      <section>
        <Style.TeamMembers className="inner-padding-content" id="down" data-aos="fade-up">
          <div>
            <h1> Our Team </h1>
            {/* <p>
              We're International company base in <br></br>Nigeria! Come and
              meet us.
            </p> */}
          </div>
          <Style.TeamBios>
            <div className="bios-wrapper" data-duration="20000" data-aos-delay="1000" data-aos="fade-up">
            {status === "fetched" && (<>
             {mainData.map((bio, i) => (

                <div className="bios-container"
                  key={i}
                  onClick={() => {
                    toggleSidebar();
                    setshowTeamMember(bio)
                  }}>
                  <div className="bio-img">
                    <img className="bio-img" src={bio.image} alt="bios"></img>
                    <TeamIcon />
                  </div>
                  <div className="details">
                    <h3> {bio.name} </h3> <h6> {bio.title} </h6>
                  </div>
                </div>
            ))}   
            </>)}
            
            {status === "error" && <h1>Network Error</h1>}

            {status === "fetching" && <Loader />}
            </div>
          </Style.TeamBios>
          

          {showTeam ? (
            <>
              <TeamModal className="modal" toggle={toggleSidebar} teamMember={showTeamMember} />
            </>
          ) : null}
        </Style.TeamMembers>
      </section>
      <Footer />
    </>
  );
}
