import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Home from "./pages/home/Home";
import Contact from "./pages/contact-us/Contact";
import { darkTheme } from "./utils/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./utils/global";
import Team from "./pages/team/Team";
import Develop from "./pages/expertise/Develop";
import Iot from "./pages/iot/Iot";
import About from "./pages/about-us/about";
import Project from "./pages/project/Project";
import  Branding from "./pages/branding/Branding";
import Marketing from "./pages/digitalmarketing/Marketing"
import Consultancy from "./pages/consultancy/Consultancy"
import ProjectDetails from "./pages/project-details/ProjectDetails";
import Policy from "./pages/privacypolicy/Policy"

import AOS from "aos";
import "aos/dist/aos.css";

const AppRoute = () => {
  //   const [theme, setTheme] = useState("dark");
  // const selectedTheme = theme !== "light" ? lightTheme : darkTheme;

  const selectedTheme = darkTheme;

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 200,
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={selectedTheme}>
        <GlobalStyles />
        <Router>
          <Home path="/" />
          <Contact path="contact" />
          <Team path="team" />
          <Develop path="develop" />
          <Iot path="iot" />
          <About path="about" />
          <Project path="project" />
          <Branding path="branding" />
          <Marketing path="marketing" />
          <Consultancy path="consultancy" />
          <ProjectDetails path="/project/:id" />
          <Policy path="/policy" />
        </Router>
      </ThemeProvider>
    </>
  );
};

export default AppRoute;
