import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Style from "./iot.style";
import img from "../../assets/img/undraw_circuit_board_4c4d.svg";
import card from "../../assets/img/undraw_Credit_card_re_blml.svg";
import stock from "../../assets/img/shutterstock_652948648@2x.png";
import { useLocation } from "@reach/router";

export default function Iot() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <div>
        <Style.Main>
          <div className="main inner-padding-content">
            <div className="header-content">
              <Style.IotMain>
                <div className="content-left">
                  <h2 data-aos="fade-up">IOT & Embedded Systems</h2>
                  <div className="divider"></div>
                  <p data-aos="fade-up">
                    Through our integrated Embedded and IoT services, Raaxo Synergy helps build intelligent & connected devices that can be remotely monitored and controlled while leveraging edge and cloud computing for a host of intelligent applications and analytics.
                  </p>
                  <a href="#down" className="main-btn">
                    Explore
                  </a>
                </div>
                <div className="content-right" data-aos="fade-up">
                  <img className="shutter-img" src={stock} alt=""></img>
                </div>
              </Style.IotMain>
            </div>
          </div>
        </Style.Main>
        <Style.IotSub>
          <section className="iot-content inner-padding-content" id="down">
            <div className="iot-wrapper">
              <div className="iot-text">
                <div className="text-wrapper">
                  <div className="mate">
                    <h1 className="outline">I</h1>
                    <h2>IOT</h2>
                  </div>
                  <div className="iot-des">
                    <h3 className="heading" data-aos="fade-up">
                      Internet of Things
                    </h3>
                    <p data-aos="fade-up">
                      Driven by rapidly growing data and devices communicating with each other, IoT is revolutionizing businesses by creating new business models and new revenue streams.
                      At Raaxo Synergy, we build analytical IoT solutions that uncover hidden insights in the collected data and allow you to leverage them for your business purposes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="iot-img" data-aos="fade-up">
                <img src={img} alt="circuit"></img>
              </div>
            </div>
            <div className="systems-wrapper ">
              <div className="systems-img">
                <img
                  data-aos="fade-up"
                  className="iot-img"
                  src={card}
                  alt=""
                ></img>
              </div>
              <div className="system-text-wrapper" data-aos="fade-up">
                <div className="marry">
                  <h1 className="outline">E</h1>
                  <h2>
                    Embedded <span>Systems</span>
                  </h2>
                </div>
                <div>
                  <p>
                    Embedded Systems control several of the common automation systems today. We build complex embedded products that are custom designed by relying on innovative hardware and software designs. Our strength in embedded software development, hardware design and development services makes us a perfect partner for IoT product development.

                  </p>
                </div>
              </div>
            </div>
          </section>
        </Style.IotSub>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
