import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Style from "../branding/branding.style"
import Footer from "../../components/footer/Footer"
export default function Branding() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <Style.RaaxoBrand>
        <div className="header-wrapper inner-padding-content">
          <div className="hero-text">
            <h1>We Brand</h1>
            <p>
              A company’s brand communicates its business culture and identity.
              We reveal brands, help you spark the right conversation, deliver efficiency, and win.
          </p>
            <button className="main-btn">Explore</button>
          </div>
        </div>
      </Style.RaaxoBrand>
      <Style.BrandSectiontwo>
        <section className="">
          <div className="branding-contents inner-padding-content ">
            <div className="brand-text ">
              <h1>
                <span>Be heard in a </span> <br></br> Noisy world,

            </h1>
              <p>
                As a well-established brand or a start-up we believe that you are bringing something extraordinary to the world. Let us build your business through smart strategies and designs, projecting your voice in a noisy world.
            </p>
            </div>
            {/* <div className="links">
              <span>IOT & Embedded Systems</span>
              <span>Digital Marketing</span>
              <span>Consultancy</span>
            </div> */}
          </div>
          <div className="brand-content ">
            <div className="tile-container__item  ">
              <img className="tile-container__item" src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt=""></img>
            </div>
            <div className="tile-container__item">
              <div className="inner-padding-content ">
                <h2>Personalize your leads.</h2>
                <p>
                  We focus on your organizations’ strength, goals and the unique value you provide to your customers. By understanding who you are and how you make your customers’ lives better, we are able to develop powerful messaging that will attract your market and create loyalty.
                </p>
                <button className="main-btn">Explore</button>
              </div>
            </div>
          </div>
          <div className="brand-content ">
            <div className="tile-container__item">
              <div className="inner-padding-content tile-container__item">
                <h2>Speak art.</h2>
                <p>
                  At Raaxo Synergy, we build brands that speak in volumes, the values and standing of a brand and leaves a mark on future and existing clients. We enrich your quality in the eyes and minds of your public.
            </p>
                <button className="main-btn">Explore</button>
              </div>
            </div>
            <div className="tile-container__item">
              <img className="tile-container__item" src="https://images.unsplash.com/photo-1457301547464-91995555cd25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt=""></img>
            </div>
          </div>
        </section>
      </Style.BrandSectiontwo>
      <Footer />
    </>
  );
}
