import Styled from "styled-components";
import Headerbg from "../../assets/img/colored_computer.png";

const Style = {
    DevelopWrapper: Styled.section`
   display:flex;
   background:
      linear-gradient(0deg, rgb(4 6 22), rgb(4 6 22 /25%)),url(${Headerbg});
    background-size: cover;
    background-position: 50% 20%;
    width:100%;
    height:100%;
     
    .hero-item{
        width:60%
    }
        
    @media screen and (max-width: 600px) {
        .hero-item{
           width:100%;
           
           p{
               font: normal normal normal 16px/28px Century Gothic;
           }
        } 
    @media screen and (max-width: 1000px) {
        .hero-item{
           width:100%
        } 
    }
    
  `,
    DevelopHeader: Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    
    
     h3{
         color: ${({ theme }) => theme.yellow};
         font-size:4vw;

     }
     p{
         color: ${({ theme }) => theme.lightBlue};
         padding-bottom: 1em;
         line-height:1.5em;
         font: normal normal normal 20px/35px Century Gothic;
     }
    `,
    DevelopMain: Styled.div`
     height:100vh;
     @media screen and (max-width: 1000px) {
       height:100vh;
         
    }
    

     a {
        text-decoration: none;
        color: ${({ theme }) => theme.lightBlue};
        cursor: pointer;
        font: normal normal 300 15px/25px Raleway;
        opacity:.5;
        &:hover{
            opacity:1;
        }
       
      }
  h2{
      font: normal normal bold 50px/70px Century Gothic;
      color: ${({ theme }) => theme.lightBlue};
      
    }
    p{
        width:50vw;
        font-size: 26px;
      font-weight: 500; ;
      line-height: 1.5em;
      color: ${({ theme }) => theme.lightBlue};
       @media screen and (max-width: 600px) {
            font-size: 1.4em;
             width:90vw;

         }
    }
    @media screen and (max-width: 1000px) {
        h2{
          font: normal normal bold 24px/36px Century Gothic;
        } 
    }
    h2 > span {
        color: ${({ theme }) => theme.yellow};
    }
    p {
         color: ${({ theme }) => theme.lightBlue};
         line-height:1.5em;
         font: normal normal normal 20px/35px Century Gothic;
         @media screen and (max-width: 600px) {
             font-size: 16px;
         }
    }

    .main-left {
        display:flex;
        flex-direction:column;
        color: ${({ theme }) => theme.lightBlue};
        margin-top: 35vw;
        
        @media screen and (max-width: 1000px) {
            margin-top:0;
        
      }
        
            
        
    }
    .main-left > span {
        margin-top: .5em;
    }    
    .main-wrapper{
        display:flex;
        justify-content: space-between;
        @media screen and (max-width: 1000px) {
         flex-wrap:wrap;
    }
        

    }
    
    `,
    ContentWrapper: Styled.main`
   margin-bottom:5em ;
   
  `,
    Footer: Styled.div`
  margin-top:10em;
  `,
    DevelopContent: Styled.div`
    /* height:70vh; */
    @media screen and (max-width: 600px) {
         height:50vh;
         p{
             font: normal normal normal 16px/24 Century Gothic;
         }
    }
    
    h2{
          color: ${({ theme }) => theme.yellow};
    }
    .iot-content{
        display:flex;
        align-items: center; 
        
       @media screen and (max-width: 600px) {
         flex-wrap:wrap;
         margin-top:200px;
         p{
             width:100%;
         }
         }

        .tile-container__item{
          width: 100%;
          object-fit: cover;
          
        }

        @media screen and (max-width: 600px) {
         .tile-container__item > img {
             display:none;
         }
    }
   
      }
    p{
       color: ${({ theme }) => theme.lightBlue}; 
       /* padding: .5em 0; */
       font: normal normal normal 20px/24px Century Gothic;
       line-height:1.5em;
       @media screen and (max-width: 600px) {
           font-size:16px;
           padding:0;
       }
    }
    }

    .content-left{
      
        display:flex;
        flex-direction:column;
        
        
    }
    
    
        
    }
    
    `,



};
export default Style;

