import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Style from "./contact.styles";
import locationImg from "../../assets/img/Group 66 (4).svg";
import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/footer/Footer";
import { useLocation } from "@reach/router";
import axios from "axios";

export default function Contact() {
  const location = useLocation();

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");

  // const [loader, setLoader] = useState(false);
  const onChangefirstname = (e) => {
    setFirstName(e.target.value)
  };
  const onChangelastname = (e) => {
    setLastName(e.target.value)
  };
  const onChangeemail = (e) => {
    setEmail(e.target.value)
  };
  const onChangeorganization = (e) => {
    setOrganization(e.target.value)
  };
  const onChangemessage = (e) => {
    setMessage(e.target.value)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleSubmit = (e) => {
    console.log(firstname);
    e.preventDefault();
    //   setLoader(true);

    //   db.collection("contacts")
    //     .add({
    //       firstname: firstname,
    //       lastname: lastname,
    //       email: email,
    //       organization: organization,
    //       message: message,
    //     })
    //     .then(() => {
    //       setLoader(false);
    //       alert("Your message has been submitted");
    //     })
    //     .catch((error) => {
    //       alert(error.message);
    //       setLoader(false);
    //     });

    //   setFirstName("");
    //   setLastName("");
    //   setEmail("");
    //   setOrganization("");
    //   setMessage("");
    const user = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      organization: organization,
      message: message
    };

    console.log('heres')
    console.log(user)

    axios.post(`https://communicato-app.herokuapp.com/api/v1/user/email`,  user)
      
      .then((res) => {
       
        console.log(res);
        console.log(res.data);
      }).catch((error) => {

        // setLoading(false)
        console.log(error)

      })
  };

  return (
    <div>
      <nav>
        <Navbar />
      </nav>
      <Style.HeroWrapper>
        <section className="inner-padding-content">
          <Style.ContactHero>
            <div className="hero-wrapper section-data ">
              <div className="contact-hero ">
                <h1>
                  Say <br></br> <span>Hello</span>
                </h1>
                <p>We're here for you, and we love a new challenge.</p>
                <a className="main-btn" href="#down">
                  Get in touch
                </a>
              </div>
            </div>
          </Style.ContactHero>
        </section>
      </Style.HeroWrapper>
      <section className="inner-padding-content" id="down">
        <Style.ContactLocation>
          <div className="location-wrapper">
            <div className="circle-wrapper">
              <div className="outer-circle" >
                <div className="inner-circle"></div>
                <div className="outer-circle-text">
                  <h2>Our Offices</h2>
                  <p>
                    We're International company base in Nigeria! Come and meet
                    us.
                  </p>
                </div>
              </div>
            </div>
            <div className="location">
              <h1 className="big-text">Nigeria Offices</h1>
              <div className="location-img">
                <img className="location-img" src={locationImg} alt=""></img>
              </div>
            </div>
            <div className="address">
              <div className="text-address">
                <p>
                  No. 7, Madiana Close, Off Dar-Es-Salam St, <br></br> Wuse
                  900288, 2, Abuja
                </p>
                <button className="main-btn">Get Direction</button>
              </div>
              <div className="phone-number">
                <span>+234 812 383 4240</span>
                <span>+234 812 383 4240</span>
              </div>
            </div>
            <div className="contact-form">
              <div className="form-left">
                <h2>
                  We'll be happy to help you in <span> any way we can.</span>
                </h2>
                <p>
                  If you wish to contact us, please fill in the form or send us
                  an email at info@raaxo.ng
                </p>
              </div>
              <div className="form-right">
                <form className="form" onSubmit={handleSubmit}>
                  <input
                    placeholder="First Name*"
                  // value={firstname}
                  // onChange={(e) => setFirstName(e.target.value)}
                  onChange={onChangefirstname}
                  />
                  

                  <input
                    placeholder="Last Name*"
                  // value={lastname}
                  // onChange={(e) => setLastName(e.target.value)}
                  onChange={onChangelastname}
                  />

                  <input
                    placeholder="Email*"
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={onChangeemail}
                  />

                  <input
                    placeholder="Organization*"
                  // value={organization}
                  // onChange={(e) => setOrganization(e.target.value)}
                  onChange={onChangeorganization}
                  />

                  <textarea
                    placeholder="Message"
                  // value={message}
                  // onChange={(e) => setMessage(e.target.value)}
                  onChange={onChangemessage}
                  ></textarea>

                  <button className="main-btn" type="submit">
                    Send
                  </button>
                  
                </form>
              </div>
            </div>
          </div>
        </Style.ContactLocation>
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
}
