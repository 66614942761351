import Styled from "styled-components"


const Style = {
    Privacy: Styled.div`
    display:flex;
    justify-content:center;
    h2 {
            font-size: 1.5em;
            color: ${({ theme }) => theme.yellow};
          }
    h3 {
            font-size: 1.2em;
            color: ${({ theme }) => theme.yellow};
            opacity:.7;
          }
          p {
            color: ${({ theme }) => theme.lightBlue};
            font-size: 0.95em;
            max-width:70vw;
          }

          .privacy-policy{
              margin-top:100px;
          }

          li{
              color: ${({ theme }) => theme.lightBlue};
          }

          
    `,

}

export default Style;