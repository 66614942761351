import styled from "styled-components";

const Style = {
  Footer: styled.footer`
    padding-top: 3em;
    background: ${({ theme }) => theme.footerBackground};
  `,

  FooterHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6em;
    padding-right: 6em;

    h3 {
      font-size: 3vw;
      font-weight: 800;
      color: ${({ theme }) => theme.footerDarkBlue};

      span {
        color: ${({ theme }) => theme.yellow};
      }
    }
  `,

  FooterMain: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    padding-left: 6em;
    padding-right: 6em;

    .footer__item {
      h4 {
        color: ${({ theme }) => theme.lightBlue};
        font-size: 1.5em;
        font-weight: 700;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          padding: 0.3em 0 0.3em;
          color: ${({ theme }) => theme.lightBlue};
          font-weight: 400;
          font-size: 0.8em;

          a {
            text-decoration: none;
            cursor: pointer;
            color: ${({ theme }) => theme.lightBlue};
            font-weight: 300;
          }
        }
      }
    }
  `,

  FooterLegal: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .divider {
      width: 100%;
      border-top: 0.03em solid ${({ theme }) => theme.footerDarkBlue};
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      margin-bottom: 1em;

      li {
        padding: 0.3em 0 0.3em 0;

        h4 {
          padding: 0;
          margin: 0;
          font-size: 1.5em;
          color: ${({ theme }) => theme.lightBlue};
        }

        a {
          text-decoration: none;
          color: ${({ theme }) => theme.lightBlue};
          cursor: pointer;
          font-weight: 300;
          font-size: 0.8em;
        }
      }
    }
  `,

  FooterFoot: styled.div`
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    margin: 0 !important;

    p {
      color: ${({ theme }) => theme.lightBlue};
      font-size: 0.8em;
      font-weight: 300;
    }
  `,
};

export default Style;
