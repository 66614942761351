import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "@reach/router";
import Style from "../project/project.style";
import Footer from "../../components/footer/Footer";
import useFetch from "../../components/useFetch";
import Loader from "../../components/loader/Loader"
import data from "./projectdata";

export default function Project(props) {
  const [categoryFilter, setCategoryFilter] = useState("");
  // const [status, setStatus] = useState("fetching");
  // let filteredData;
  // let setCategoryFilter = (categoryName) => {
  //   filteredData = data.projects.filter((x) => x.category === categoryName);
  //   console.log(filteredData);
  // };
  const categoryFilterHandleChange = (e) => {
    setCategoryFilter(e.target.getAttribute(props.category));
  };

  const url = `https://raaxo-console.herokuapp.com/api/v1/projects`;
  const { status, data, error } = useFetch(url);
  const mainData = data.projects;
  if (status === "fetched") {
    console.log(mainData.map((project) => project.category));
  }

  return (
    <>
      <header>
        <Navbar />
      </header>
      <Style.ProjectHero>
        <div className="hero-wrapper ">
          <div className="inner-padding-content">
            <h1>Our Projects</h1>
          </div>
        </div>
      </Style.ProjectHero>
      <Style.Filter>
        <div className="filter">
          {/* <div className="section-header inner-padding-content">
            <h1>Our Projects</h1>
          </div> */}
          <div className="filter-category">
            <div className="filter-inner">
              {/* Category filter */}
              <div className="categories">
                <div className="category-item item1">
                  <p
                    onClick={() => {
                      setCategoryFilter("All");
                    }}
                  >
                    All
                  </p>
                </div>
                <div className="category-item item2">
                  <p
                    onClick={() => {
                      setCategoryFilter("Branding");
                    }}
                  >
                    Branding
                  </p>
                </div>

                <div className="category-item item2">
                  <p
                    onClick={() => {
                      setCategoryFilter("software");
                    }}
                  >
                    Software
                  </p>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </Style.Filter>

      <Style.ProjectStyle>
        <div className="inner-padding-content project-wrapper">
          {status === "error" && <h2>Network Error</h2>}
          {status === "fetched" && (
            <>
              {mainData.map((project) => (
                <div className="project">
                  <Link to={"/project/" + project.id}>
                    {
                      <img
                        className="project-image"
                        src={project.image}
                        alt="project"
                      ></img>
                    }
                  </Link>

                  {/* project details */}
                  <div className="project-details">
                    <div className="project-details__header">
                      <img src={project.logo} alt={project.name} />
                      <p>{project.name}</p>
                    </div>

                    <div className="project-details__footer">
                      <p>{project.name}</p>

                      <Link to={"/project/" + project.id}>
                        <span>&#10010;</span>
                      </Link>
                    </div>
                  </div>
                  {/* End project details */}
                </div>
              ))}
            </>
          )}
          {status === "fetching" && <Loader />}
        </div>
      </Style.ProjectStyle>

      <Footer />
    </>
  );
}
